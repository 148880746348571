import React from "react";
import styled from "@emotion/styled";
import BackgroundImageSvg from "../../assets/images/background.svg";
import AddressForm from "./AddressForm";

const AboutWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #defaff; /* Your background color */
  position: relative; /* Required for absolute positioning of patches */
  overflow: hidden;
`;

const Heading = styled.div`
  font-family: "Inter";
  font-size: 28px;
  font-weight: 500;
  line-height: 29.05px;
  text-align: center;
  color: #000000;
  margin: 50px auto;
`;

const BackgroundImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const UserAddressWrapper = styled.div`
padding-top:0;
display: flex;
justify-content: center;
align-items: center;
`
const FormWrapper = styled.div`
box-shadow: 0px 4px 30px 0px #42424229;
border-radius: 16px;
width: 60%;
background: white;
z-index: 1;
padding-top: 40px;
padding-bottom: 40px;
margin-bottom: 40px;

@media (max-width: 600px) {
    width: 90%;
  }
`

function UserAddress() {
  return (
    <AboutWrapper>
      <Heading>Address</Heading>
      <BackgroundImage src={BackgroundImageSvg} alt="bg-img" />
      <UserAddressWrapper>

        <FormWrapper>
            <AddressForm   />
            
        </FormWrapper>

      </UserAddressWrapper>
    </AboutWrapper>
  );
}

export default UserAddress;
