import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  showSuccessModal,
  showUpdatePasswordModal,
} from "../../store/reducer/reducer";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { post } from "../../api_helper/api_helper";
import CloseModal from "./CloseModal";

import { Box, Typography, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect } from "react";
const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border-radius: 16px;
  width: clamp(450px, 30%, 636px);
  padding: 20px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    width: 90%;
  }
`;

const Heading = styled.div`
  font-family: "Inter";
  font-size: 41px;
  line-height: 33.89px;
  font-weight: 600;
  margin-bottom: 45px;
  color:#424545;
`;

const inputStyles = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '13px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'primary.main',
    },
  },
  '& .MuiInputBase-input': {
    padding: '16.5px 14px',
  },

};

const labelStyles = {
  display: 'block',
  marginBottom: '5px',
  fontFamily: 'Inter, sans-serif',
  fontSize: '21px',
  fontWeight: 500,
  color: "#5E6363",
};

function UpdatePasswordModal() {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const forgotPasswordInfo = useSelector(
    (state) => state.general.forgotPasswordInfo
  );
  const displayModal = useSelector(
    (state) => state.general.showUpdatePasswordModal
  );

  useEffect(() => {
    if (displayModal) {
      // Disable scrolling by applying overflow hidden to the body
      document.documentElement.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling by resetting overflow to auto
      document.documentElement.style.overflow = 'auto';
    }

    // Cleanup function to ensure scrolling is restored when the component is unmounted
    return () => {
      document.documentElement.style.overflow = 'auto';
    };
  }, [displayModal]); // Trigger effect whenever the isOpen state chang
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const { message } = await post("/api/auth/forgot-password/new-password", {
        new_password: values.new_password,
        confirm_password: values.confirm_password,
        update_token: forgotPasswordInfo.token,
        mobilenumber: forgotPasswordInfo.phone_number,
      });
      setLoading(false);
      dispatch(showUpdatePasswordModal(false));
      dispatch(showSuccessModal("password"));
      toast.success(message);
      formik.resetForm();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
    }
  };

  const ValidationSchema = Yup.object().shape({
    new_password: Yup.string()
      .strict(true)
      .required("Password is required!")
      .test("new_password", "Invalid password", (value) => {
        if (!value || value.length < 6) {
          throw new Yup.ValidationError(
            "Password should be at least 6 characters long",
            null,
            "new_password"
          );
        }
        if (!/[A-Z]/.test(value)) {
          throw new Yup.ValidationError(
            "Password should contain at least one uppercase letter",
            null,
            "new_password"
          );
        }
        if (!/[a-z]/.test(value)) {
          throw new Yup.ValidationError(
            "Password should contain at least one lowercase letter",
            null,
            "new_password"
          );
        }
        if (!/\d/.test(value)) {
          throw new Yup.ValidationError(
            "Password should contain at least one digit",
            null,
            "new_password"
          );
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
          throw new Yup.ValidationError(
            "Password should contain at least one special character",
            null,
            "new_password"
          );
        }
        return true;
      }),
    confirm_password: Yup.string()
      .strict(true)
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Please confirm your password"),
  });
  const formik = useFormik({
    initialValues: { new_password: "", confirm_password: "" },
    onSubmit,
    validationSchema: ValidationSchema,
    validateOnChange: false, // Disable validation on change
    validateOnBlur: false,    // Disable validation on blur
  });

  return (
    <>
      <Modal
        open={displayModal}
        onClose={() => {
          dispatch(showUpdatePasswordModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LoginFormWrapper>
          <CloseModal
            closeFn={() => {
              dispatch(showUpdatePasswordModal(false));
            }}
          />
          <Heading>New Password</Heading>

          <div>
            <React.Fragment>
              <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Box sx={{ marginTop: '13px' }}>
                  <Typography component="label" htmlFor="new-password-input" sx={labelStyles}>
                    New Password
                  </Typography>
                  <TextField
                    id="new-password-input"
                    variant="outlined"
                    color="primary"
                    type={showNewPassword ? "text" : "password"} // Toggle password visibility
                    name="new_password"
                    placeholder="New Password"
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.new_password)}
                    helperText={formik.touched.new_password && formik.errors.new_password}
                    fullWidth
                    sx={inputStyles}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowNewPassword((prev) => !prev)}
                            edge="end"
                          >
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box sx={{ marginTop: '19px', marginBottom:"19px" }}>
                  <Typography component="label" htmlFor="confirm-password-input" sx={labelStyles}>
                    Confirm Password
                  </Typography>
                  <TextField
                    id="confirm-password-input"
                    variant="outlined"
                    color="primary"
                    type={showConfirmPassword ? "text" : "password"} // Toggle password visibility
                    name="confirm_password"
                    placeholder="Confirm Password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.confirm_password)}
                    helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                    fullWidth
                    sx={inputStyles}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowConfirmPassword((prev) => !prev)}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>


                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{
                      margin: "20px auto",
                      background: "#00B4D8",
                      color: "white",
                      borderRadius: "8px",
                      height: "53px",
                      width: "100%",
                    }}
                    variant="contained"
                    type="submit"
                    disabled={false}
                  >
                    Reset Password
                  </Button>
                </div>
              </form>
            </React.Fragment>
          </div>
        </LoginFormWrapper>
      </Modal>
    </>
  );
}

export default UpdatePasswordModal;
