import React from "react"
import styled from "@emotion/styled"
import DetailsAccordian from "../Accordian";
import "./Accordin.css"
const AccordianBoxWrapper = styled.div`
width: 50%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 20px;
@media (max-width: 800px) {
    width: 90%;
  }

    @media (max-width: 1100px) {
    width: 90%;
    margin: 10px auto;

  }
`;
function AccordianBox({ product }) {
  // const finalProduct = product?.contain_variants
  // ? { ...product, ...product?.product_variants[0] }
  // : product;

  const finalProduct = product?.contain_variants && product?.product_variants?.length > 0
    ? { ...product, ...product.product_variants[0] }
    : product;

    function MeasurementString() {
      return (
        `
          <ul style="margin: 0;">
            <li>Weight: ${finalProduct.weight} kg</li>
            <li>Length: ${finalProduct.length} cm</li>
            <li>Height: ${finalProduct.height} cm</li>
            <li>Width: ${finalProduct.width} cm</li>
          </ul>
        `
      )
    }
  return <AccordianBoxWrapper>
    {product?.key_features && product.key_features !== "-" ? (
      <DetailsAccordian
        name="Key features"
        info={product.key_features}
        className="details-accordian"
 
      />
    ) : null}
    {/* <DetailsAccordian name={`Product measurements`} info={MeasurementString()} /> */}

    {MeasurementString() && MeasurementString() !== "-" ? (
  <DetailsAccordian
  name="Product measurements"
  info={MeasurementString()}
  sx={{
    "& ul": {
      margin: "0 !important",  // Apply margin 0 to ul inside the accordion
    },
  }}
/>

) : null}


    {product?.materials && product?.materials !== "-" ? (
      <DetailsAccordian
        name="Materials"
        info={product?.materials}
        style={{ 
          marginTop: '0px' // Inline style for h1 (if h1 is inside DetailsAccordian)
        }}
      />
    ) : null}


    {/* <DetailsAccordian name={`Materials`} info={product?.materials} /> */}
    {/* <DetailsAccordian name={`Assembly instructions`} info={product?.assembly_instructions} /> */}
    {product?.assembly_instructions && product?.assembly_instructions !== "-" ? (
      <DetailsAccordian
        name="Assembly instructions"
        info={product?.assembly_instructions}
        style={{ 
          marginTop: '0px' // Inline style for h1 (if h1 is inside DetailsAccordian)
        }}
      />
    ) : null}

    {product?.additional_information && product?.additional_information !== "-" ? (
      <DetailsAccordian
        name="Additional information"
        style={{ 
          marginTop: '0px' // Inline style for h1 (if h1 is inside DetailsAccordian)
        }}
        info={product?.additional_information}
      />
    ) : null}
    {/* <DetailsAccordian name={`Additional information`} info={product?.additional_information} /> */}
  </AccordianBoxWrapper>
}
export default AccordianBox