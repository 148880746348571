import React from "react";
import styled from "@emotion/styled";
import HeadingWithLinks from "./HeadingWithLinks";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/system";
import ShiporaWebLogo from "../../assets/images/Shipora_Logo_Main.png";
import Facebook from "../../assets/images/Facebook.png"
import Twitter from "../../assets/images/Twitter.png"
import Instagram from "../../assets/images/Instagram.png"
import LinkedIn from "../../assets/images/LinkedIn.png"
import YouTube from "../../assets/images/YouTube.png"
import { Divider } from "@mui/material"; // Import Divider
import "./footer.css"
const FooterParentWrapper = styled.div`
  min-height: 228px;
  width: 100%;
  background: #003E4C;
  color: white;
  position: relative;
  padding-top: 20px;
  // margin-top:20px;
  box-sizing: border-box;

    @media (max-width: 600px) {
      padding-top:0px;
  }
`;

const FooterBottomWraper = styled.div`
 min-height: 60px;
  width: 100%;
  background: #25626f;
  color: white;
  position: relative;
  padding-top: 20px;
  box-sizing: border-box;

  
`;

const LinkWrapper = styled.div`
  width: 80%;
  margin: auto;
  max-width:1525px; 
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
      flex-direction: column;
      padding: 20px 0px;
      align-items:unset;
  }
`;

const MainLogoImage = styled.img`
  // width: 174px;
  // height: 64px;
// margin-right:5%;
      width: auto;
          margin-top:17px;
    height: 52px;
  cursor: pointer;
  
  @media (max-width: 1150px) {
    // width: 140px;
    // height: 49px;
            width: auto;
        height: 32px;
  }
  @media (max-width: 600px) {
 width: 149px;
        height: auto;
  }

     @media (max-width: 475px) {
    // width: 108px;
      width: 120px;
      height:auto;
   
  }
`;
const FooterCredits = styled.div`
  width: 80%;
  margin: auto;
    max-width:1525px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
  }

`;

const SocialMediaLinks = styled.div`

  display: flex;

 gap: 20px;
`;

const LastWrapper = styled.div`

  display: flex;
  

flex-direction:column;


  @media (max-width: 600px) {
margin-top:12px;
  }
`;

const StaticText = styled.div`

  display: flex;
gap: 15px;
    font-weight: 400;
    font-size: 16px;
    margin-top:25px;

flex-direction:column;
font-family: 'DM Sans', sans-serif;  // Apply DM Sans font-family

 @media (max-width: 600px) {
font-size:14px;
    margin-top:12px;
  }
`;






function Footer() {
  const matches = useMediaQuery('(max-width:600px)');

  return (


    <>
      <FooterParentWrapper>
        <LinkWrapper>

          <MainLogoImage

            src={ShiporaWebLogo}
          />

          <div className="HeadWithLinks">


          <HeadingWithLinks
            // heading={`Get to Know Us`}
            links={[{ link: "/about", linkName: "About Us" }]}
          />

          <hr className="hr-divider" />

          <HeadingWithLinks
            // heading={`Make Money With Us`}
            links={[{ link: "", linkName: "Sell on Shipora" }]}
          />
          <hr className="hr-divider" />

          <HeadingWithLinks
            // heading={`Let Us Help You`}
            links={[
              { link: "/user-profile", linkName: "Your Account" },
              // { link: "/faq", linkName: "Help" },
            ]}
          />
          </div>

          <LastWrapper>

            <SocialMediaLinks>
              <Link to="#">
                <img
                  src={Facebook} // Path to your local PNG icon
                  alt="Facebook"

                />
              </Link>

              <Link to="#">
                <img
                  src={Twitter} // Path to your local PNG icon
                  alt="Facebook"

                />
              </Link>

              <Link to="#">
                <img
                  src={Instagram} // Path to your local PNG icon
                  alt="Facebook"

                />
              </Link>

              <Link to="#">
                <img
                  src={LinkedIn} // Path to your local PNG icon
                  alt="Facebook"

                />
              </Link>

              <Link to="#">
                <img
                  src={YouTube} // Path to your local PNG icon
                  alt="Facebook"

                />
              </Link>

            </SocialMediaLinks>

            <StaticText>
              <span>Shipora – Your trusted marketplace and
                            </span>
  <span>Shipment partner from India to Canada.</span>
                          </StaticText>


          </LastWrapper>
        </LinkWrapper>

      </FooterParentWrapper>

      <FooterBottomWraper>

        <FooterCredits>
          <div>
            <Link to={"https://techrabbit.io/"} style={{ textDecoration: "none", fontFamily: "Inter", color: "white", marginRight: "20px", marginBottom: matches ? "10px" : "", fontSize: matches ? "14px" : "unset" }}>© {new Date().getFullYear()} Shipora, All rights reserved. Developed by Tech Rabbit</Link>
          </div> <div><Link onClick={() => {
            window.scrollTo(0, 0);
          }} to={"/terms-and-condition"} style={{ textDecoration: "underline", fontFamily: "Inter", color: "white", marginRight: "20px", marginBottom: matches ? "10px" : "", fontSize: matches ? "14px" : "unset" }}>Terms & Conditions</Link>
            <Link onClick={() => {
              window.scrollTo(0, 0);
            }} to={"/privacy-policy"} style={{ textDecoration: "underline", fontFamily: "Inter", color: "white", marginRight: "20px", marginBottom: matches ? "10px" : "", fontSize: matches ? "14px" : "unset" }}>Privacy Policy</Link>
          </div>  </FooterCredits>
      </FooterBottomWraper>
    </>
  );
}

export default Footer;
