import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { post, get } from "../../api_helper/api_helper";
import CartItemsBox from "../../components/UserCart/CartItemsBox";
import CartDetailsBox from "../../components/UserCart/CartDetailsBox";
import UpdateTypeOfShipment from "../../components/UserCart/UpdateTypeOfShipment";
import { setUserDetails } from "../../store/reducer/reducer";
import empty_order from "../../assets/images/Group33257.png";
import CloseIcon from '@mui/icons-material/Close'; // Import the Close Icon
import { setGrandShippingValue } from "../../store/reducer/reducer";
import { setCartData } from "../../store/reducer/reducer";
import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { formatDollars } from "../../helper/generalFunctions";
const WidthAdjuster = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;
max-width:1525px;
// max-height:78vh;
// overflow-y:auto;

// scrollbar-width: none; /* Hide scrollbar for Firefox */
//   -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */

//   &::-webkit-scrollbar {
//     display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
//   }

  @media (max-width: 600px) {
  width: 90%;
  }

`;

const Heading = styled.div`
font-family:"Inter";
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 36px;
  font-weight: 600;
  line-height: 29.05px;
  margin: 30px auto;
  color:#3E4D50;
`;

const DisplayFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  // @media (max-width: 800px) {
  //   flex-direction: column;
  // }

  max-height:59vh;
overflow-y:auto;

scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  }
`;

const CheckoutBtn = styled.button`
  width: 50%;
  background: #00b4d8;
  color: white;
  border-radius: 8px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 19px;

  
  @media (max-width: 1000px) {
font-size: 18px;
  }

    @media (max-width: 508px) {
font-size: 16px;
  }

   @media (max-width: 400px) {
font-size: 14px;
  }
`;
function UserCart() {
  const dispatch = useDispatch();
  const [cart, setCart] = useState({});
  const [coupon_code, setCouponCode] = useState("");
  const user = useSelector((state) => state.general.user);
  const [refetch, setRefetch] = useState(false);
  const navigate = useNavigate();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [shippingPrices, setShippingPrices] = useState({});

  const grandShippingValue = useSelector(
    (state) => state.general.grandShippingValue
  );

  
  const cartData = useSelector(
    (state) => state.general.cartData
  );

  console.log("Cart Data", cartData)


  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { userProfile } = await get("/api/auth/profile-info");
        dispatch(setUserDetails(userProfile || {}));
      } catch (e) {
        console.error("Error fetching user details:", e);
      }
    };

    fetchUserDetails();
  }, [dispatch]);	


  const checkTotal=grandShippingValue + cart?.subTotal

  console.log("Grand Shipping Value", grandShippingValue)
  console.log("Cart Sub Total", cart?.subTotal)

  console.log("Cehckout Value", checkTotal)
  // const [grandShippingValue, setGrandShippingValue] = useState(0);


  // const updateShippingPrice = (itemId, shippingPrice) => {
  //   setShippingPrices((prev) => {
  //     const updatedPrices = { ...prev, [itemId]: shippingPrice };

  //     // Recalculate grand shipping value
  //     const totalShipping = Object.values(updatedPrices).reduce((sum, price) => sum + price, 0);
  //     setGrandShippingValue(totalShipping);

  //     return updatedPrices;
  //   });
  // };
  const updateShippingPrice = (itemId, shippingPrice) => {
    setShippingPrices((prev) => {
      const updatedPrices = { ...prev, [itemId]: shippingPrice };


      // Recalculate grand shipping value
      const totalShipping = Object.values(updatedPrices).reduce((sum, price) => sum + price, 0);
      console.log("Updated Price", totalShipping)

      // Dispatch the total shipping value to Redux
      dispatch(setGrandShippingValue(totalShipping));

      return updatedPrices;
    });
  };
  // console.log("Grand", grandShippingValue)

  // async function syncGuestCartToUser() {
  //   try {
  //     const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
  
  //     if (guestCart.length > 0) {
  //       for (const item of guestCart) {
  //         await post("/api/cart/add-to-cart-or-increase-quantity", {
  //           productId: item?.productId,
  //           shipment_type: item?.shipment_type,
  //           ...(item?.product_variant_id && {
  //             productVariantId: item?.product_variant_id,
  //           }),
  //         });
  //       }
  
  //       // Clear guest cart from localStorage after successful sync
  //       localStorage.removeItem("guestCart");
  //       toast.success("Guest cart items merged with your account!");
  //     }
  //   } catch (error) {
  //     console.error("Error syncing guest cart:", error);
  //     toast.error("Failed to merge guest cart items. Please try again.");
  //   }
  // }
  
  async function syncGuestCartToUser() {
    try {
      // Fetch guest cart from localStorage
      const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
  
      if (guestCart.length > 0) {
        for (const item of guestCart) {
          try {
            // Call the add-to-cart API for each item
            await post("/api/cart/add-to-cart-or-increase-quantity", {
              productId: item?.productId,
              shipment_type: item?.shipment_type,
              ...(item?.productVariantId && {
                productVariantId: item?.productVariantId,
              }),
            });
            console.log(`Item ${item.productId} added to cart successfully.`);
          } catch (error) {
            // Log any error but don't show a failure message to the user
            console.log(`Item ${item.productId} already exists in the cart or failed to add.`);
          }
        }
  
        // Clear the guest cart from localStorage after successful sync
        localStorage.removeItem("guestCart");
        toast.success("Guest cart items merged with your account!");
      }
    } catch (error) {
      console.error("Error syncing guest cart:", error);
      toast.error("Failed to merge guest cart. Please try again.");
    }
  }
  
  const handleCheckoutClick = () => {

    if (user) {
      navigate("/checkout")
    }

    else {

      if (!user) {
        toast.error("Please login to continue");
        return;
      }

      toast.error("Something Went Wrong");

    }
  };

  const updateShippingPricesFromCart = (cartItems) => {
    const updatedPrices = {};
    
    cartItems.forEach(item => {
      // Assuming you have a way to calculate or retrieve the shipping price for each item
      updatedPrices[item?.id] = item?.shippingPrice || 0; // Assuming "shippingPrice" is the key
    });
  
    setShippingPrices(updatedPrices);
  
    // Recalculate the total shipping value
    const totalShipping = Object.values(updatedPrices).reduce((sum, price) => sum + price, 0);
    console.log("Updated Total Shipping after syncing", totalShipping);
  
    // Dispatch the updated shipping value to Redux
    dispatch(setGrandShippingValue(totalShipping));
  }
  

  // Function to close the popup
  const handleClosePopup = () => {
    setIsPopupVisible(false); // Hide the popup
  };
  // useEffect(() => {
  //   if (user) {
  //     // Sync guest cart to user's account and fetch updated cart
  //     syncGuestCartToUser()
  //       .then(() => getUserCartInfo())
  //       .catch((error) => console.error("Error syncing cart:", error));
  //   } else {
  //     // Load guest cart from localStorage
  //     const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
  //     setCart(guestCart);
  //   }
  // }, [user]);

  useEffect(() => {
    if (user) {
      // Sync guest cart to user's account and fetch updated cart
      syncGuestCartToUser()
        .then(() => getUserCartInfo())
        .then((userCart) => {
          updateShippingPricesFromCart(userCart?.cartItems || []);
        })
        .catch((error) => console.error("Error syncing cart:", error));
    } else {
      // Load guest cart from localStorage
      const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
      setCart(guestCart);
      
      // Update shipping prices from guest cart
      updateShippingPricesFromCart(guestCart);
    }
  }, [user]);
  

  // console.log(cart)

  // async function getUserCartInfo(dontUseCoupon) {
  //   try {
  //     const cartInfo = await post("/api/cart", {
  //       user_id: user?.id,
  //       ...(coupon_code && !dontUseCoupon ? { coupon_code } : {}),
  //     });

  //     setCart(cartInfo);
  //   } catch (e) {
  //     setCart({});
  //   }
  // }

  async function getUserCartInfo(dontUseCoupon) {
    try {
      const cartInfo = await post("/api/cart", {
        user_id: user?.id,
        ...(coupon_code && !dontUseCoupon ? { coupon_code } : {}),
      });

      console.log("Cart data after adding item:", cartInfo);


      setCart(cartInfo);
      dispatch(setCartData(cartInfo));

    } catch (e) {
      setCart({});
    }
  }

  // console.log("Check Set Cart",cart.cartItems)

  // console.log("GrandShippingValue",grandShippingValue)

  return (
    <WidthAdjuster>

      <Dialog open={isPopupVisible} onClose={handleClosePopup} sx={{
        '& .MuiPaper-root': {

          borderRadius: '16px',  // Border radius
        },
      }} >


        <DialogTitle>

          {/* Close Icon Button */}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClosePopup}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 16,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CartDetailsBox
            cart={cart}
            getUserCartInfo={getUserCartInfo}
            coupon_code={coupon_code}
  
            setCouponCode={setCouponCode}
        
          />
        </DialogContent>


      </Dialog>





      <UpdateTypeOfShipment getUserCartInfo={getUserCartInfo} />
      <Heading>Cart</Heading>
      {(user ? !cart?.cartItems?.length : !cart.length) ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "500px",
          }}
        >
          <img src={empty_order} alt="Empty Cart" />
          <p style={{ fontFamily: "Inter", color: "#7B7B7B" }}>
            You Don't have any Cart Item
          </p>
        </div>
      ) : (
        <>
          <DisplayFlex>
            <CartItemsBox cart={cart} setRefetch={setRefetch}  getUserCartInfo={getUserCartInfo} updateShippingPrice={updateShippingPrice} setCart={setCart} setShippingPrices={setShippingPrices} />
           { console.log("THE CART", cart)}
            {/* <CartDetailsBox
          cart={cart}
          getUserCartInfo={getUserCartInfo}
          coupon_code={coupon_code}
          setCouponCode={setCouponCode}
        /> */}
          </DisplayFlex>

          <CheckoutBtn onClick={handleCheckoutClick}>
            Checkout {checkTotal ? formatDollars(checkTotal) : null}
          </CheckoutBtn>  
        </>
      )}
      



    </WidthAdjuster>
  );
}

export default UserCart;
