import React from "react";
import styled from "@emotion/styled";
import EditNoteIcon from "@mui/icons-material/EditNote";

const AddressWrapper = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 20px 0;
  cursor: pointer;
`;
const AddressStr = styled.div`
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #5e6363;
`;

const EditBtn = styled.div`
  background: #00b4d8;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  min-height: 34px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  margin: 20px auto;
  width: 100%;
`;

function SingleAddressitem({ addStr ,func,isSelected}) {
  return (
    <AddressWrapper onClick={func} style={isSelected?{border:"2px solid #00b4d8"}:{}}>
      <AddressStr>{addStr}</AddressStr>
      <EditBtn>
        <EditNoteIcon />

        <span style={{ marginLeft: "15px" , fontSize:"14px", fontWeight:"400", fontFamily:"Inter"}}>Edit Address</span>
      </EditBtn>
    </AddressWrapper>
  );  
}

export default SingleAddressitem;
