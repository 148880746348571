import React from "react";
import styled from "@emotion/styled";
import Successfull from "../../assets/images/Successfull.png"
import { get, post } from "../../api_helper/api_helper";
import { setUserDetails } from "../../store/reducer/reducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";




const OrderSuccess = styled.div`

    display: flex
;
    flex-direction: column;
    gap: 17px;
    align-items: center;
    justify-content: center;
    padding:63px;

`;

const PlacedSuccess = styled.div`
font-family:"Inter";
color:#00B4D8;
font-size:32px;
font-weight:600;
text-align: center;




`;

const imgPlaced = styled.div`



`;


const Great = styled.span`
font-family:"Inter";
color:#25626F;
font-size:24px;
font-weight:400;

`;

const WidthAdjuster = styled.div`
  width: 80%;
  margin: 10px auto;
  margin-top: 50px;
  max-width:1525px;
  
   @media (max-width: 600px) {
   width: 97%;
  }
 
`;




function OrderPlaced() {

    const dispatch = useDispatch();

useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { userProfile } = await get("/api/auth/profile-info");
        dispatch(setUserDetails(userProfile || {}));
      } catch (e) {
        console.error("Error fetching user details:", e);
      }
    };

    fetchUserDetails();
  }, [dispatch]);	


    return (
        <WidthAdjuster>


            <OrderSuccess>

                <imgPlaced>


                    <img src={Successfull}></img>

                </imgPlaced>
                <Great>Great!</Great>
                <PlacedSuccess>Your Order Successfully
                    Placed!</PlacedSuccess>


            </OrderSuccess>

        </WidthAdjuster>
    );
}

export default OrderPlaced;
