import { createSlice } from "@reduxjs/toolkit";

export const generalSlice = createSlice({
  name: "general",
  initialState: {
    user: JSON.parse(localStorage.getItem("userInfo")) || null,

    showLoginModal: false,
    showSignupModal: false,
    showOtpModal: false,
    showForgotPasswordModal: false,
    showUpdatePasswordModal: false,
    showSuccessModal: null,
    tempRegistrationData: null,
    isRegistering: false,
    showForgotPasswordOtp: false,
    forgotPasswordInfo: null,
    selectedSubCategory: null,
    searchTerm: "",
    showSelectShipmentModal: false,
    // wishlistItems: [], // Array to store wishlist items

    grandShippingValue: 0,

    selectedProduct: null,
    selectProductToFetchDetails: null,
    showUpdateCartItemShipment: null,
    selectedCartItem: null,
    selectedDeliveryAddress: null,
    selectedCategoryFromLandingPage: null,
    isBuyNowItemSelected: null,
    dynamicPageContent: null,
    modalSource: "homepage", // New state to track the source
    ClickInfo: false,
    userDetails: {},
    cartData:{},
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },

    setCartData: (state, action) => {
      state.cartData = action.payload;
    },

    addUserInfo: (state, action) => {
      state.user = action.payload;
    },
    clickUserInfo: (state) => {
      state.ClickInfo = true;
    },

    resetClickInfo: (state) => {
      state.ClickInfo = false; // Reset ClickInfo back to false
    },

    setModalSource: (state, action) => {
      state.modalSource = action.payload; // Set source (wishlist or homepage)
    },
    logout: (state) => {
      state.user = null;
    },

    setGrandShippingValue: (state, action) => {
      state.grandShippingValue = action.payload;
    },
    showLoginModal: (state, action) => {
      state.showLoginModal = action.payload;
    },
    handleDynamicPageContent: (state, action) => {
      state.dynamicPageContent = action.payload;
    },
    showSignupModal: (state, action) => {
      state.showSignupModal = action.payload;
    },
    showOtpModal: (state, action) => {
      state.showOtpModal = action.payload;
    },
    showForgotPasswordModal: (state, action) => {
      state.showForgotPasswordModal = action.payload;
    },
    showUpdatePasswordModal: (state, action) => {
      state.showUpdatePasswordModal = action.payload;
    },
    showSuccessModal: (state, action) => {
      state.showSuccessModal = action.payload;
    },
    tempRegistrationData: (state, action) => {
      state.tempRegistrationData = action.payload;
    },
    isRegistering: (state, action) => {
      state.isRegistering = action.payload;
    },
    showForgotPasswordOtp: (state, action) => {
      state.showForgotPasswordOtp = action.payload;
    },
    forgotPasswordInfo: (state, action) => {
      state.forgotPasswordInfo = action.payload;
    },
    selectedSubCategory: (state, action) => {
      state.selectedSubCategory = action.payload;
    },
    searchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    showSelectShipmentModal: (state, action) => {
      state.showSelectShipmentModal = action.payload;
      // state.wishlistItems = action.payload.wishlistItems ;
    },

    // updateWishlistItems: (state, action) => {
    //   state.wishlistItems = action.payload;  // Update the wishlist items in the global state
    // },

    selectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    selectProductToFetchDetails: (state, action) => {
      state.selectProductToFetchDetails = action.payload;
    },
    showUpdateCartItemShipment: (state, action) => {
      state.showUpdateCartItemShipment = action.payload;
    },
    selectedCartItem: (state, action) => {
      state.selectedCartItem = action.payload;
    },
    selectedDeliveryAddress: (state, action) => {
      state.selectedDeliveryAddress = action.payload;
    },
    selectedCategoryFromLandingPage: (state, action) => {
      state.selectedCategoryFromLandingPage = action.payload;
    },
    isBuyNowItemSelected: (state, action) => {
      state.isBuyNowItemSelected = action.payload;
    },
  },
});

export const {
  selectedCategoryFromLandingPage,
  selectedDeliveryAddress,
  selectedCartItem,
  showUpdateCartItemShipment,
  selectProductToFetchDetails,
  searchTerm,
  selectedProduct,
  showSelectShipmentModal,
  setModalSource,
  selectedSubCategory,
  isRegistering,
  showSuccessModal,
  showUpdatePasswordModal,
  addUserInfo,
  logout,
  handleDynamicPageContent,
  showLoginModal,
  showSignupModal,
  showOtpModal,
  showForgotPasswordModal,
  tempRegistrationData,
  showForgotPasswordOtp,
  forgotPasswordInfo,
  isBuyNowItemSelected,
  setGrandShippingValue,
  clickUserInfo,
  resetClickInfo,
  ClickInfo,
  setUserDetails,
  setCartData,
  // updateWishlistItems
} = generalSlice.actions;

export default generalSlice.reducer;
