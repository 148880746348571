import React from "react";
import styled from "@emotion/styled";
import SelectedTabBox from "./SelectedTabBox";
import CartItemsBox from "./CartItemsBox";
import CartDetailsBox from "./CartDetailsBox";
import { useSelector } from "react-redux";
const AddressFormWrapper = styled.div`
  // width: 60%;
  margin: auto;
box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  background: white;
padding-bottom: 30px;
  border-radius:16px;
      width: clamp(578px, 48%, 806px);

  // @media (max-width: 800px) {
  //   width: 97%;
  // }

    @media (max-width: 800px) {
    // width: 97%;
        width: 85%;
  }

   @media (max-width: 550px) {
    // width: 97%;
        width: 100%;
        box-shadow:none;
  }
`;

const AddressBox = styled.div`
  width: 100%;
  border: 1.5px solid rgb(227, 227, 227);
  border-radius:8px;
  min-height: 75px;
  padding: 10px;
  box-sizing: border-box;
  text-align: justify;
  margin-bottom: 20px;
`;

const Heading2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 36px;
  font-weight: 600;
  line-height: 29.05px;
  padding: 40px;
  color:rgba(58, 58, 58, 1);
`;


const WidthAdjuster = styled.div`
  width: 90%;
  margin: auto;
`;


const AddressHeading = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  color: #595959;
  // margin: 20px 0;
  margin: 13px 0 7px 0px;
`;
function OrderInfo({
  currentTab,
  cart,
  coupon_code,
  setCouponCode,
  getUserCartInfo,
}) {
  const selectedAddress = useSelector(
    (state) => state.general.selectedDeliveryAddress
  );
 

  return (
    <AddressFormWrapper>
       <Heading2> Order Summary</Heading2>
      <SelectedTabBox currentTab={currentTab} />

      <WidthAdjuster>
        <CartItemsBox cart={cart} hide={true} />
      </WidthAdjuster>

      <WidthAdjuster>
        <AddressHeading>Address</AddressHeading>
        <AddressBox>
          {`${selectedAddress?.address || ""}, ${
            selectedAddress?.area_name || ""
          }, ${selectedAddress?.city || ""}, ${selectedAddress?.state || ""}, ${
            selectedAddress?.country || ""
          }, ${selectedAddress?.zipcode || ""}`}
        </AddressBox>
      </WidthAdjuster>
      <WidthAdjuster>
        <CartDetailsBox
          getUserCartInfo={getUserCartInfo}
          cart={cart}
          coupon_code={coupon_code}
          setCouponCode={setCouponCode}
        />
      </WidthAdjuster>
    </AddressFormWrapper>
  );
}

export default OrderInfo;
