import React, { useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserInfo,
  isRegistering,
  showOtpModal,
  tempRegistrationData,
} from "../../store/reducer/reducer";
import {
  showSuccessModal,
  showUpdatePasswordModal,
} from "../../store/reducer/reducer";
import styled from "@emotion/styled";
import OtpImage from "../../assets/images/getOtpImage.svg";
import { toast } from "react-toastify";
import { Button } from "@mui/base";
import { post, put } from "../../api_helper/api_helper";
import { CircularProgress } from "@mui/material";
import CloseModal from "../Auth/CloseModal";
const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border-radius: 16px;
  width: clamp(393px, 30%, 670px);
  padding: 20px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    width: 90%;
  }
`;

const ResendOtp = styled.div`
  font-family: "Inter";
  font-size: 15px;
  font-weight: 400;
  line-height: 18.15px;
  text-align: center;
  margin-bottom: 10px;
`;

const ShowImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TexttoDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Heading = styled.div`
  font-weight: 400;
  font-size: 20px;
  font-family: "Inter";
  margin: 10px auto;
`;
const SubHeading = styled.div`
  font-weight: 400;
  font-size: 20px;
  font-family: "Inter";
  margin: 10px auto;
`;

function OtpScreen({
  setOTP,
  OTP,
  inputRefs,
  setShowOtpModal,
  showOTPModal,
  couuntryCode,
  phoneNumber,
  setCountryCode,
  getUserProfile,
  setPhoneNumber
}) {
  const length = 4;
  const user = useSelector((state) => state.general.user);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const encryptPhoneNumber = (phoneNumber) =>
    phoneNumber?.replace(
      /(\d{5})(\d+)/,
      (_, visible, hidden) => `${visible}${"*".repeat(hidden.length)}`
    );

  const verifyOtp = async () => {
    try {
      setLoading(true);
      await put("/api/auth/profile-info-mobile-verify", {
        new_mobile_number: phoneNumber,
        otp: OTP.join(""),
        old_mobile_number: user?.mobilenumber,
        country_code:couuntryCode
      });
      toast.success("Mobile number updated");
      dispatch(showSuccessModal("mobile"));
      setShowOtpModal(false);
      setLoading(false);
      setOTP(Array(length).fill(""));
      setCountryCode("");
      setPhoneNumber("");
      dispatch(addUserInfo({...user,mobilenumber:phoneNumber}))
      const userInfo = JSON.parse(localStorage.getItem("userInfo"))
      localStorage.setItem("userInfo", JSON.stringify({...userInfo,mobilenumber:phoneNumber}));
      getUserProfile()
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
      console.log(e);
    }
  };
  const handleTextChange = (input, index) => {
    const newPin = [...OTP];
    newPin[index] = input;
    setOTP(newPin);

    if (input.length === 1 && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (input.length === 0 && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }

    if (newPin.every((digit) => digit !== "")) {
      //   onComplete(newPin.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && OTP[index] === "") {
      inputRefs.current[index - 1]?.focus();
    }
  };
  return (
    <>
      <Modal
        open={showOTPModal}
        onClose={() => {
          setShowOtpModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LoginFormWrapper>
          <CloseModal
            closeFn={() => {
              setShowOtpModal(false);
            }}
          />
          <ShowImage>
            <img
              alt="otp"
              src={OtpImage}
              style={{ width: "171px", height: "140px", margin: "40px auto" }}
            />
          </ShowImage>

          <TexttoDisplay>
            <Heading>Please enter the OTP to verifiy your account</Heading>
            <SubHeading>
              A OTP has been sent to{" "}
              {`${couuntryCode}-${encryptPhoneNumber(phoneNumber)}`}
            </SubHeading>
          </TexttoDisplay>
          <div
            style={{
              margin: "20px auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
           
            }}
          >
            {Array.from({ length }, (_, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={OTP[index]}
                onChange={(e) => handleTextChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputRefs.current[index] = el)}
                style={{
                  fontFamily: "Inter",
                  fontWeight: 400,
                  fontSize: "24px",
                  marginRight: index === length - 1 ? "0" : "10px",
                  width: "54px",
                  height: "54px",
                  border: "1px solid #B4B4B4",
                  borderRadius: "4px",
                  textAlign: "center",
                }}
              />
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={verifyOtp}
              disabled={loading}
              style={{
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: "20px",
                height: "54px",
                color: "white",
                background: "#00B4D8",
                borderRadius: "8px",
                border: "none",
                width: "224px",
                margin: "20px auto",
              }}
            >
              {loading ? <CircularProgress /> : `Verify`}
            </Button>
          </div>
          {/* <ResendOtp onClick={() => resendOtp()}>Resend OTP</ResendOtp> */}
        </LoginFormWrapper>
      </Modal>
    </>
  );
}

export default OtpScreen;
