import React, { useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "@emotion/styled";
import CountryCodes from "../../assets/json/countrycode.json";

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Divider,
} from "@mui/material";
import CloseModal from "../Auth/CloseModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { put } from "../../api_helper/api_helper";
const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border-radius: 16px;
  width: 30%;
  padding: 20px;
  box-sizing: border-box;
  width: clamp(450px, 30%, 670px);
  @media (max-width: 800px) {
    width: 90%;
  }
`;
const Heading = styled.div`
  font-family: "Inter";
  font-size: 28px;
  line-height: 33.89px;
  font-weight: 600;
  margin-bottom: 20px;
`;

function UpdateUserMobile({
  setShowUpdateUserMobile,
  showUpdateUserMobileModal,
  setShowOtpModal,
  setCountryCode,
  setPhoneNumber,
  phoneNumber,
  couuntryCode,
  userInfo,
}) {
  const handlePhoneNumberChange = (e) => {
    // Ensure only digits are entered and limit to 10 digits
    const value = e.target.value.replace(/\D/g, "").slice(0, 10); // Remove non-digits and limit to 10 digits
    setPhoneNumber(value);
  };

  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.general.user);
  // ${userInfo?.country_code}-${userInfo?.mobilenumber}
  // async function updateUserMobileNumber() {
  //   // if (user?.mobilenumber === phoneNumber) {
  //   //   return toast.error("Mobile number cannot be same!");
  //   // }

  //   try {
  //     setLoading(true);
  //     await put("/api/auth/profile-info-mobile", {
  //       new_mobile_number: phoneNumber,
  //       new_country_code: couuntryCode,
  //     });
  //     setShowOtpModal(true);
  //     setShowUpdateUserMobile(false);
  //     setLoading(false);
  //   } catch (e) {
  //     setLoading(false);
  //     toast.error(e?.response?.data?.message || "something went wrong");
  //     console.log(e);
  //   }
  // }

  async function updateUserMobileNumber() {
    // Ensure userInfo is available before comparison
    if (!userInfo) {
      return toast.error("User information is unavailable.");
    }
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
      return toast.error("Mobile number must be exactly 10 digits.");
    }
    // Compare old mobile number with the new one
    if (
      `${userInfo?.country_code}-${userInfo?.mobilenumber}` ===
      `${couuntryCode}-${phoneNumber}`
    ) {
      return toast.error("Old and New Mobile Number Can Not be the same");
    }

    try {
      setLoading(true);
      // API call to update mobile number
      await put("/api/auth/profile-info-mobile", {
        new_mobile_number: phoneNumber,
        new_country_code: couuntryCode,
      });
      // After successful API call, show OTP modal
      setShowOtpModal(true);
      setShowUpdateUserMobile(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "Something went wrong");
      console.log(e);
    }
  }

  return (
    <>
      <Modal
        open={showUpdateUserMobileModal}
        onClose={() => {
          setShowUpdateUserMobile(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LoginFormWrapper>
          <CloseModal
            closeFn={() => {
              setShowUpdateUserMobile(false);
            }}
          />
          <Heading>Enter New Mobile Number</Heading>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography
                  component="label"
                  htmlFor="phone-number"
                  style={{
                    color: "#5E6363",
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    marginBottom: "5px",
                  }}
                >
                  Phone Number
                </Typography>
                <TextField
                  fullWidth
                  id="phone-number"
                  name="mobile_number"
                  variant="outlined"
                  placeholder="ex. 9876453234"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  error={false} // Implement validation logic as needed
                  helperText={""} // Implement helper text based on validation
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* Country Code Dropdown */}
                        <Autocomplete
                          disablePortal
                          sx={{
                            "& .MuiAutocomplete-clearIndicator": {
                              display: "none !important",
                            },
                            "& .MuiInputBase-input": {
                              minWidth: "60px !important",
                            },
                            "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input":
                              {
                                minWidth: "66px !important",
                              },
                          }}
                          value={
                            CountryCodes.find(
                              (code) => code.dial_code === couuntryCode
                            ) || null
                          } // Ensure null if no match is found
                          onChange={(_, value) => {
                            setCountryCode(value?.dial_code || ""); // Handle null
                          }}
                          options={CountryCodes}
                          getOptionLabel={(option) =>
                            `${option.flag} ${option.dial_code}`
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder="ex. +1"
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                style: { width: 80, fontSize: "0.875rem" },
                              }}
                            />
                          )}
                        />
                        {/* Divider */}
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            mx: 1,
                            height: 28,
                            alignSelf: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.2)",
                          }}
                        />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "8px",
                      borderColor: "rgba(227, 227, 227, 1)",
                    },
                  }}
                  inputProps={{
                    maxLength: 10, // Limit to 10 digits
                    pattern: "[0-9]*", // Allow only digits
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                margin: "20px auto",
                background: "#00B4D8",
                color: "white",
                borderRadius: "24px",
                width: "80%",
                height: "43px",
              }}
              variant="contained"
              type="submit"
              onClick={updateUserMobileNumber}
              disabled={loading}
            >
              {loading ? <CircularProgress /> : `Update`}
            </Button>
          </div>
        </LoginFormWrapper>
      </Modal>
    </>
  );
}

export default UpdateUserMobile;
