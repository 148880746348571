import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import styled from "@emotion/styled";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "slick-carousel/slick/slick.css";
import { useSelector } from "react-redux";
import "./Accordin.css"
import "slick-carousel/slick/slick-theme.css";
import SingleProduct from "./SingleProduct";
import { useMediaQuery } from "@mui/material";
import { get, post } from "../../api_helper/api_helper";
import RecentlyProduct from "../../components/LandingPage/RecentlyProduct";
const WidthAdjuster = styled.div`

  margin: 10px auto;
  margin-top: 50px;
  max-width:1525px;
  
   @media (max-width: 600px) {
   width: 97%;
  }
 
`;
const HeadingWithLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;
const Heading = styled.div`
  font-family: "Inter";
  font-weight: 500;
  font-size: 24px;
  line-height: 29.05px;
  @media (max-width: 600px) {
    font-size: 19px;
  line-height: 24.05px;
  }
`;

const RecentlyPro = styled.div`
  font-family: "Inter";
  font-weight: 600;
  font-size: 24px;
  color:#3E4D50;
  line-height: 29.05px;
  @media (max-width: 600px) {
    font-size: 19px;
  line-height: 24.05px;
  }
`;
const ButtonWrapper = styled.div`
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  
`;
const CarouselButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
 
 width: 6%;

 
  @media (max-width: 600px) {
    width: 20% !important;

  }

    @media (max-width: 1800px) {
    width: 6%;

  }

   @media (max-width: 1271px) {
    width: 11%;

  }

  
   @media (max-width: 990px) {
    width: 15%;

  }

     @media (max-width: 400px) {
    width: 22% !important;

  }

    @media (max-width: 370px) {
    width: 25% !important;

  }
`;
const SliderWrapper = styled.div`
  margin-top: 40px;
  margin:auto;
`;
const ProductWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

// Import other dependencies...

const ProductDetailsProductSlider = ({
  products,
  getUserWishlistItems,
  setWishlistItems,
  wishlistItems,
}) => {
  const matches = useMediaQuery("(max-width:600px)");
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Slider settings
  const settings = {
    dots: false,
    infinite: false, // Disable infinite looping
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index), // Track the current slide
    responsive: [
      {
        breakpoint: 1479,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1036,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const slidesToShow =
    settings.responsive.find((resp) => window.innerWidth <= resp.breakpoint)
      ?.settings.slidesToShow || settings.slidesToShow;

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const totalSlides = products.length;
console.log("Current Slide Show", currentSlide)
console.log("Slide to show",slidesToShow)
console.log("Total Slides", totalSlides)

  return (
    <WidthAdjuster>
      <HeadingWithLink>
        <RecentlyPro>Recently Viewed Products</RecentlyPro>
        <CarouselButtonWrapper>
          {/* Left Arrow */}
          <ButtonWrapper
            onClick={previous}
            style={{ visibility: currentSlide === 0 ? "hidden" : "visible" }}
          >
            <ArrowBackIosIcon
              style={
                matches
                  ? { fontSize: "12px", color: "#595959", paddingLeft: "10px" }
                  : { fontSize: "16px", color: "#595959", paddingLeft: "6px" }
              }
            />
          </ButtonWrapper>

          {/* Right Arrow */}
          <ButtonWrapper
            onClick={next}
            style={{
              visibility:
                currentSlide >= totalSlides - slidesToShow
                  ? "hidden"
                  : "visible",
            }}
          >
            <ArrowForwardIosIcon
              style={
                matches
                  ? { fontSize: "12px", color: "#595959" }
                  : { fontSize: "16px", color: "#595959" }
              }
            />
          </ButtonWrapper>
        </CarouselButtonWrapper>
      </HeadingWithLink>
      {/* <RecentlyProduct></RecentlyProduct> */}

      <SliderWrapper>
        <Slider ref={sliderRef} {...settings}>
          {products.map((item) => (
            <RecentlyProduct
              key={item.id}
              product={item}
           

              getUserWishlistItems={getUserWishlistItems}
              wishlistItems={wishlistItems}
              setWishlistItems={setWishlistItems}
            />
          ))}
        </Slider>
      </SliderWrapper>
    </WidthAdjuster>
  );
};

export default ProductDetailsProductSlider;
