import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useSelector , useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setUserDetails } from "../../store/reducer/reducer";
import { get } from "../../api_helper/api_helper";
import { post } from "../../api_helper/api_helper";
import OrderHeader from "../../components/UserOrders/OrderHeader";
import OrderBox from "../../components/UserOrders/OrderBox";
import OrderStatusModal from "../../components/UserOrders/OrderStatus";
import OrderInfoModal from "../../components/UserOrders/OrderInfoModal";

const OrdersWrapper = styled.div`
  width: 100%;
  background: #dffaff;
  height: 100%;
  padding-bottom: 70px;
`;

const WidthAdjuster = styled.div`
  width: 80%;
  margin: auto;
  padding-top: 50px;
  max-width:1525px;
  @media (max-width: 600px) {
    width: 97%;
  }
`;

function UserOrder() {
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalOrderInfo, setDisplayModalOrderInfo] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const user = useSelector((state) => state.general.user);
  const navigate = useNavigate();
  

  const dispatch = useDispatch();

  const [status, setStatus] = useState(0); // 0 for Active, 1 for Completed
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [allOrders, setAllOrders] = useState([]); // Store all orders
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { userProfile } = await get("/api/auth/profile-info");
        dispatch(setUserDetails(userProfile || {}));
      } catch (e) {
        console.error("Error fetching user details:", e);
      }
    };

    fetchUserDetails();
  }, [dispatch]);	
  async function getUserOrders() {
    try {
      setLoading(true);
      const orders = await post("/api/ecom/get-all-orders/user", {
        user_id: user?.id,
        ...(search && { search }),
      });
      setAllOrders(orders); // Store all orders in allOrders state
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!user) {
      navigate("/");
      toast.error("Please login!");
    } else {
      getUserOrders();
    }
  }, [user]);

  // Filter orders based on status for rendering
  const filteredOrders = allOrders.filter((order) =>
    status === 0 ? order.order_status !== "DELIVERED" : order.order_status === "DELIVERED"
  );

  return (
    <>
      <OrderStatusModal
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
      />
      <OrderInfoModal
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        displayModalOrderInfo={displayModalOrderInfo}
        setDisplayModalOrderInfo={setDisplayModalOrderInfo}
      />
      <OrdersWrapper>
        <WidthAdjuster>
          <OrderHeader
            getUserOrders={getUserOrders}
            search={search}
            setSearch={setSearch}
            status={status}
            setStatus={setStatus}
          />
          <OrderBox
            setDisplayModalOrderInfo={setDisplayModalOrderInfo}
            setSelectedOrder={setSelectedOrder}
            orders={filteredOrders} // Pass filtered orders to OrderBox
            setDisplayModal={setDisplayModal}
          />
        </WidthAdjuster>
      </OrdersWrapper>
    </>
  );
}

export default UserOrder;