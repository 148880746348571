import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { post, get } from "../../api_helper/api_helper";
import styled from "@emotion/styled";
import ProductInputBox from "../../components/Products/ProductsSearchBoxInput";
import ProductSingleProduct from "../../components/Products/SingleProduct";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { setUserDetails } from "../../store/reducer/reducer";
const WidthAdjuster = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;
  @media (max-width: 600px) {
    width: 97%;
  }
  max-width: 1525px;
`;

const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  color:#3E4D50;
  font-family:"Inter";
  line-height: 29.05px;
  margin: 20px 0;
  @media (max-width: 600px) {
    font-size: 15px;
  }
`;

const DisplayEnd = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: row;
  }
`;

const CustomPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    color: #00B4D8;
    border-color: #00B4D8;
  }
  .MuiPaginationItem-root.Mui-selected {
    background-color: #00B4D8;
    color: white;
    width:36px;
    height:36px;
    border-radius:19px;
    font-size:16px;
  }
  .MuiPaginationItem-ellipsis {
    color: #00B4D8;
  }
  .MuiPaginationItem-icon {
    color: #00B4D8;
  }
`;


const PaginationInfo = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color:#7B7B7B;
`;


const ProductContainer = styled.div`
  margin: 20px auto;
`;

const FlexWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin: 50px 0;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ArrowBackIconStyled = styled(ArrowBackIcon)`
  cursor: pointer;

  font-size: 18px; /* Larger size for visibility */
  padding: 6px; /* Padding for better clickability */
  border: 2px solid #25626f; /* Add border for modern design */
  border-radius: 50%; /* Make it circular */
  transition: all 0.3s ease;
        background-color: #25626f; /* Fill background on hover */
    color: #fff; /* Change arrow color to white */

  &:hover {
         background-color: #25626f; /* Fill background on hover */
    color: #fff; /* Change arrow color to white */
    transform: scale(1.1); /* Subtle zoom effect */
  }

  &:active {
    transform: scale(1); /* Reset zoom on click */
  }

      @media (max-width: 600px) {
    font-size: 14px;
    padding: 4px;
  }
`;
const ViewEnd = styled.div`
  display: none;
  margin: 20px auto;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  @media (max-width: 1024px) {
    display: flex;
  }
`;


const PaginationUI = styled.div`
    margin: 74px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    padding: 10px 14px;
    border-top: 1.4px solid rgba(0, 0, 0, 0.2);

         @media (max-width: 600px) {
    width:  80% ;

  }

`;

const GoBack = styled.span`
  font-weight: 500;
  font-size: 20px;
  font-family: "Inter";
  cursor: pointer;
`;

function ProductListing() {
  const [wishlistItems, setWishlistItem] = useState([]);
  const searchText = useSelector((state) => state.general.searchTerm);
  const user = useSelector((state) => state.general.user);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { userProfile } = await get("/api/auth/profile-info");
        dispatch(setUserDetails(userProfile || {}));
      } catch (e) {
        console.error("Error fetching user details:", e);
      }
    };

    fetchUserDetails();
  }, [dispatch]);	
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component is mounted
  }, []);
  // Access the selected subcategory passed from location.state
  const subCategory = location.state?.selectedSubCategory;
  console.log("Sub", subCategory)

  // const childID=subCategory.id;
  // const subID=subCategory.sub_category_id;



  const handleBack = () => {
    navigate(-1);
  };

  const itemsPerPage = 15;

  // async function getProducts() {
  //   try {
  //     const productsInfo = await post(
  //       `/api/products${searchText ? `?search=${searchText}` : ""}`
  //     );
  //     setProducts(productsInfo.products);
  //   } catch (error) {
  //     console.error("Error fetching all products:", error);
  //   }
  // }

  // async function getProductsWithCategory() {
  //   try {
  //     const productsInfo = await post(`/api/products${searchText ? `?search=${searchText}` : ""}`);

  //     // Filter based on child and subcategory IDs
  //     const filteredProducts = productsInfo.products.filter(
  //       (product) => 
  //         product.child_category_id === childID &&
  //         product.sub_category_id === subID
  //     );

  //     setProducts(filteredProducts);
  //   } catch (error) {
  //     console.error("Error fetching products by category:", error);
  //   }
  // }

  // async function getUserWishlistItems() {
  //   if (user) {
  //     try {
  //       const items = await post(`/api/wishlist`, {});
  //       setWishlistItem(items?.wishlist?.wishlist_items || []);
  //     } catch (error) {
  //       console.error("Error fetching wishlist items:", error);
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (subCategory) {
  //     getProductsWithCategory(); // Fetch products based on category
  //   } else {
  //     console.warn("Subcategory is null, fetching default products");
  //     getProducts(); // Fetch all products if no category selected
  //   }
  //   getUserWishlistItems();
  // }, [subCategory, searchText, user]);

  // const handlePageChange = (event, value) => {
  //   setCurrentPage(value);
  // };
  // Ensure subCategory exists and access childID and subID conditionally
  const childID = subCategory?.id;
  const subID = subCategory?.sub_category_id;

  async function getProducts() {
    try {
      const productsInfo = await post(
        `/api/products${searchText ? `?search=${searchText}` : ""}`
      );
      setProducts(productsInfo.products);
    } catch (error) {
      console.error("Error fetching all products:", error);
    }
  }

  async function getProductsWithCategory() {
    try {
      const productsInfo = await post(`/api/products${searchText ? `?search=${searchText}` : ""}`);

      // Filter based on child and subcategory IDs if they exist
      const filteredProducts = productsInfo.products.filter(
        (product) =>
          (!childID || product.child_category_id === childID) &&
          (!subID || product.sub_category_id === subID)
      );

      setProducts(filteredProducts);
    } catch (error) {
      console.error("Error fetching products by category:", error);
    }
  }

  async function getUserWishlistItems() {
    if (user) {
      // Fetch wishlist items from API for logged-in users
      try {
        const items = await post("/api/wishlist", {});
        setWishlistItem(items?.wishlist?.wishlist_items || []);
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    } else {
      // Fetch wishlist items from local storage for guest users
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      setWishlistItem(storedWishlist);
    }
  }




  // useEffect(() => {
  //   console.log("PopularProductSlider: wishlistItems updated:", wishlistItems);
  // }, [wishlistItems]);


  useEffect(() => {
    // Call this on initial render to load wishlist items
    getUserWishlistItems();
  }, []); // Run only once on mount

  useEffect(() => {
    console.log("ProductSlider: wishlistItems updated:", wishlistItems);
  }, [wishlistItems]);

  useEffect(() => {
    if (subCategory) {
      getProductsWithCategory(); // Fetch products based on category
    } else {
      console.warn("Subcategory is null, fetching default products");
      getProducts(); // Fetch all products if no category selected
    }

  }, [subCategory, searchText, user]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);

    window.scrollTo(0, 0); // Scroll to the top when the component is mounted

  };


  // Calculate pagination details
  const totalPages = Math.ceil(products.length / itemsPerPage);
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
  console.log("CurrentProducts", currentProducts)

  // console.log(subCategory.sub_category.name)
  // console.log("WishLIst",wishlistItems)


  //Mobile Devices Logic

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);




  return (
    <WidthAdjuster>
      <ViewEnd>
        <ArrowBackIconStyled
          onClick={handleBack}
        />
      </ViewEnd>
      <DisplayEnd>
        {subCategory ? (
          <Heading>{`${subCategory.sub_category.name} > ${subCategory.name || ""}`}</Heading>
        ) : (
          <Heading>{null}</Heading>
        )}
        <ProductInputBox />
      </DisplayEnd>

      <ProductContainer>
        <FlexWrapper>
          {currentProducts.map((item) => (
            <ProductSingleProduct
              key={item.id}
              product={item}
              displayAddToCartBtn={true}
              getUserWishlistItems={getUserWishlistItems}
              wishlistItems={wishlistItems}
            />
          ))}
        </FlexWrapper>
      </ProductContainer>

      {products?.length > 15 && (
        <PaginationUI>
          <PaginationInfo>
            {`Page ${currentPage} of ${totalPages}`}
          </PaginationInfo>
          <Stack spacing={2}>
            <CustomPagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                "& .MuiPaginationItem-root.Mui-selected": {
                  borderRadius: "6px !important",
                },


              }}
            />
          </Stack>
        </PaginationUI>
      )}
    </WidthAdjuster>
  );
}

export default ProductListing;
