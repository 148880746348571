import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CountryCodes from "../../assets/json/countrycode.json";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import { get, post } from "../../api_helper/api_helper";
import { Autocomplete, Checkbox, Grid, TextField, Typography, InputAdornment, Divider } from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectedDeliveryAddress } from "../../store/reducer/reducer";
import { useNavigate } from "react-router-dom";

import axios from 'axios';
const AddressFormWrapper = styled.div`
  width: 60%;
  margin: auto;
  box-shadow: 0px 4px 30px 0px #42424229;
  background: white;
  padding-bottom: 30px;

  @media (max-width: 800px) {
    width: 97%;
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 29.05px;
  padding: 40px;
`;

const WidthAdjuster = styled.div`
  width: 90%;
  margin: auto;
`;

const ContinueButton = styled.button`
  width: 100%;
  height: 52px;
  background: #00b4d8;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  border-radius: 8px;
  border: none;
  outline: none;
  margin: 20px auto;
  cursor: pointer;
`;
// const Divider = styled.div`
//   border: 1px solid #e3e3e3;
//   width: 100%;
//   margin: 40px 0;
// `;

const HeadingSaved = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
`;

const labelStyles = {
    display: 'block',
    marginBottom: '5px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    color: "#242626",
};
function SupportForm() {
    const [userAddresses, setUserAddresses] = useState([]);
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.general.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [zipCode, setZipCode] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [error, setError] = useState('');
    const geonamesUsername = 'muneer';
    const resetZipCode = () => {
        setZipCode('');
    };
    const onSubmit = async (values) => {
        try {
            setLoading(true);

            const requestBody = {
                email: values.email_address,
                fullName: values.full_name,
                phoneNumber: values.mobile_number,
                country_code: values.mobile_number_country_code,
                type_of_query: values.type_query,
                module: values.type_product,
                subject: values.subject,
                message: values.messages,
                reason: values.reason || "", // Optional field
            };

            await post('api/admin/support', requestBody);

            setLoading(false);
            toast.success("Support request submitted successfully!");

            formik.resetForm(); // Reset form after successful submission
        } catch (e) {
            setLoading(false);
            toast.error(e?.response?.data?.message || "Something went wrong");
        }
    };


    const options = [

        { label: 'Package Inquiry', value: 'Package Inquiry' },
        { label: 'Shipment Delay', value: 'Shipment Delay' },
        { label: 'Lost Package', value: 'Lost Package' },
        { label: 'Custom Clearance Help', value: 'Custom Clearance Help' },
        { label: 'Pricing Information', value: 'Pricing Information' },

        { label: 'Tracking Updates', value: 'Tracking Updates' },
        { label: 'Insurance Claims', value: 'Insurance Claims' },
        { label: 'Payment Issues', value: 'Payment Issues' },
        { label: 'Cancellation Request', value: 'Cancellation Request' },
        { label: 'Return Shipment', value: 'Return Shipment' },
        { label: 'Address Update', value: 'Address Update' },
        { label: 'Damaged Package', value: 'Damaged Package' },
        { label: 'Feedback/Complaint', value: 'Feedback/Complaint' },
        { label: 'General Assistance', value: 'General Assistance' },
        { label: 'Other', value: 'Other' },
    ];

    const validationSchema = Yup.object().shape({

        full_name: Yup.string().required("Full name is required"),
        email_address: Yup.string()
        .required("Email is required")
        .email("Invalid email address"),
        type_query: Yup.string().required("Type of Query is required"),
        type_product: Yup.string().required("Type of Product is required"),
        subject: Yup.string().required("Subject is required"),
        messages: Yup.string().required("Messages is required"),

        mobile_number_country_code: Yup.string().required(
            "Country code is required"
        ),


        // mobile_number: Yup.string().required("Phone number is required"),

         mobile_number: Yup.string()
              .strict(true) // Strict mode will prevent automatic string coercion
              .required('Phone number is required!')
              .matches(/^\d+$/, 'Phone number must contain only digits') // Ensures the phone number contains only digits
              .length(10, 'Phone number must be exactly 10 digits'), // Ensures the number is exactly 10 digits
        
        
        reason: Yup.string().test(
            "reason-required",
            "Reason is required",
            function(value) {
                const { type_query } = this.parent;
                // If "Other" is selected, validate "reason" is required
                if (type_query === "Other" && !value) {
                    return false;
                }
                return true;
            }
        ),


        
    });
    const formik = useFormik({
        validationSchema,
        initialValues: {

            full_name: "",
            email_address: "",
            type_query: "",
            type_product: "",
            subject: "",
            messages: "",
            mobile_number_country_code: "",
            mobile_number: "",
            reason: "",

        },

        validateOnBlur: true, // Only validate on blur (when the field loses focus)
        validateOnChange: false, // Do not validate while the user is typing
        onSubmit,
    });
    async function getUserDeliveryAddress() {
        try {
            const addressInfo = await get(`/api/get-delivery-address`);
            setUserAddresses(addressInfo || []);
        } catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        if (!user) {
            navigate("/");
        } else {
            getUserDeliveryAddress();
        }
    }, []);

    //   useEffect(() => {
    //     if (zipCode.length > 0) {
    //       const fetchLocationData = async () => {
    //         try {
    //           const response = await axios.get('http://api.geonames.org/postalCodeLookupJSON', {
    //             params: {
    //               postalcode: zipCode.replace(/\s+/g, ''), // Clean spaces from postal code
    //               country: 'CA', // Canada
    //               username: geonamesUsername,
    //             },
    //           });

    //           const data = response.data;
    //           if (data && data.postalcodes && data.postalcodes.length > 0) {
    //             const location = data.postalcodes[0];

    //             // Update Formik values directly
    //             formik.setFieldValue('city', location.placeName); // Set city in Formik
    //             formik.setFieldValue('state', location.adminName1); // Set state in Formik
    //           } else {
    //             setError('Postal code not found.');
    //             formik.setFieldValue('city', '');
    //             formik.setFieldValue('state', '');
    //           }
    //         } catch (err) {
    //           setError('Error fetching data.');
    //           console.error(err);
    //         }
    //       };

    //       fetchLocationData();
    //     }
    //   }, [zipCode]);
    return (
        <form onSubmit={formik.handleSubmit}>
            <WidthAdjuster>
                <Grid container spacing={2}>
                    {/* <Grid item xs={6}>
            <TextField
              fullWidth
              id="first_name"
              label="First Name"
              name="first_name"
              variant="outlined"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.first_name)}
              helperText={formik.errors.first_name}
            />
          </Grid> */}

                    <Grid item xs={12} md={6}>

                        {/* <p>First NAme </p> */}

                        <Typography component="label" htmlFor="full_name" sx={labelStyles}>
                            Full Name
                        </Typography>
                        <TextField
                            fullWidth
                            id="full_name"
                            // label="First Name"
                            name="full_name"
                            variant="outlined"
                            placeholder="ex. John Doe"
                            value={formik.values.full_name}
                            onChange={(e) => {
                                // Allow only letters and spaces in first name
                                const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Regex to remove numbers and special characters
                                formik.setFieldValue('full_name', value);
                            }}
                            error={Boolean(formik.errors.full_name)}
                            helperText={formik.errors.full_name}
                            InputProps={{
                                style: {
                                    borderRadius: "8px",
                                    borderColor: "rgba(227, 227, 227, 1)",
                                },
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl sx={{ width: "100%" }} >
                            <Typography component="label" htmlFor="phone-number" sx={labelStyles}>
                                Phone Number
                            </Typography>
                            <TextField
                                fullWidth
                                id="phone-number"
                                name="mobile_number"
                                variant="outlined"
                                placeholder="ex. 9876453476"
                                value={formik.values.mobile_number}
                                onChange={(e) => {
                                    // Ensure only digits are entered and limit to 10 digits
                                    const value = e.target.value.replace(/\D/g, "").slice(0, 10); // Remove non-digits and limit to 10 digits
                                    formik.setFieldValue("mobile_number", value);
                                }}
                                error={Boolean(formik.errors.mobile_number) || Boolean(formik.errors.mobile_number_country_code)}
                                helperText={formik.errors.mobile_number || formik.errors.mobile_number_country_code}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {/* Country Code Dropdown */}
                                            <Autocomplete
                                                sx={{
                                                    '& .MuiAutocomplete-clearIndicator': {
                                                        display: 'none !important',
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        minWidth: '60px !important',
                                                    },
                                                    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                                                        minWidth: '66px !important',
                                                    },
                                                }}
                                                disablePortal
                                                value={CountryCodes.find(
                                                    (code) => code.dial_code === formik.values.mobile_number_country_code
                                                ) || null} // Ensure null if no match is found
                                                onChange={(_, value) => {
                                                    formik.setFieldValue("mobile_number_country_code", value?.dial_code || ""); // Handle null
                                                }}
                                                options={CountryCodes}
                                                getOptionLabel={(option) => `${option.flag} ${option.dial_code}`}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        placeholder="ex. +1"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            disableUnderline: true,
                                                            style: { width: 80, fontSize: '0.875rem' },
                                                        }}
                                                    />
                                                )}
                                            />
                                            {/* Divider */}
                                            <Divider
                                                orientation="vertical"
                                                flexItem
                                                sx={{ mx: 1, height: 28, alignSelf: "center", backgroundColor: "rgba(0, 0, 0, 0.2)" }}
                                            />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        borderRadius: "8px",
                                        borderColor: "rgba(227, 227, 227, 1)",
                                    },
                                }}
                                inputProps={{
                                    maxLength: 10, // Limit to 10 digits
                                    pattern: "[0-9]*", // Allow only digits
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* <Grid item xs={6}>


                        <Typography component="label" htmlFor="first_name" sx={labelStyles}>
                            First Name
                        </Typography>
                        <TextField
                            fullWidth
                            id="first_name"
                            // label="First Name"
                            name="first_name"
                            variant="outlined"
                            placeholder="ex. John"
                            value={formik.values.first_name}
                            onChange={(e) => {
                                // Allow only letters and spaces in first name
                                const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Regex to remove numbers and special characters
                                formik.setFieldValue('first_name', value);
                            }}
                            error={Boolean(formik.errors.first_name)}
                            helperText={formik.errors.first_name}
                            InputProps={{
                                style: {
                                    borderRadius: "8px",
                                    borderColor: "rgba(227, 227, 227, 1)",
                                },
                            }}
                        />
                    </Grid> */}
                    {/* <Grid item xs={6}>

                        <Typography component="label" htmlFor="last_name" sx={labelStyles}>
                            Last Name
                        </Typography>
                        <TextField
                            fullWidth
                            id="last_name"
                            // label="Last Name"
                            name="last_name"
                            variant="outlined"
                            placeholder="ex. James"
                            value={formik.values.last_name}
                            onChange={(e) => {
                                // Allow only letters and spaces in first name
                                const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Regex to remove numbers and special characters
                                formik.setFieldValue('last_name', value);
                            }}
                            error={Boolean(formik.errors.last_name)}
                            helperText={formik.errors.last_name}
                            InputProps={{
                                style: {
                                    borderRadius: "8px",
                                    borderColor: "rgba(227, 227, 227, 1)",
                                },
                            }}
                        />
                    </Grid> */}
                </Grid>
                <div style={{ height: "20px" }}></div>

                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <Typography component="label" htmlFor="email_address" sx={labelStyles}>
                            Email Address
                        </Typography>
                        <TextField
                            fullWidth
                            id="email_address"
                            // label="Address"
                            type="email"
                            name="email_address"
                            variant="outlined"
                            placeholder="johndoe23@gmail.com"
                            value={formik.values.email_address}
                            onChange={formik.handleChange}
                            error={Boolean(formik.errors.email_address)}
                            helperText={formik.errors.email_address}
                            InputProps={{
                                style: {
                                    borderRadius: "8px",
                                    borderColor: "rgba(227, 227, 227, 1)",
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <div style={{ height: "20px" }}></div>

                <Grid container spacing={2}>
                <Grid item xs={6}>
    <FormControl fullWidth>
        <Autocomplete
            disablePortal
            value={formik.values.type_query}
            onChange={(_, value) => formik.setFieldValue('type_query', value?.value || "")}
            id="combo-box-country"
            options={options}
            renderInput={(params) => (
                <>
                    <Typography component="label" htmlFor="type_query" sx={labelStyles}>
                        Type Of Query
                    </Typography>
                    <TextField
                        {...params}
                        placeholder="ex. Package Inquiry"
                        helperText={formik.errors.type_query}
                        error={Boolean(formik.errors.type_query)}
                        InputProps={{
                            ...params.InputProps,
                            style: {
                                borderRadius: '8px',
                                borderColor: 'rgba(227, 227, 227, 1)',
                            },
                        }}
                    />
                </>
            )}
        />
    </FormControl>
</Grid>


                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                value={formik.values.type_product}
                                onChange={(_, value) => formik.setFieldValue('type_product', value?.value || "")}
                                id="combo-box-country"
                                options={[{ label: 'Marketplace', value: 'Marketplace' }]}
                                renderInput={(params) => (
                                    <>
                                        <Typography component="label" htmlFor="type_product" sx={labelStyles}>
                                            Type Of Product
                                        </Typography>
                                        <TextField
                                            {...params}
                                            placeholder="ex. Marketplace"
                                            helperText={formik.errors.type_product}
                                            error={Boolean(formik.errors.type_product)}
                                            InputProps={{
                                                ...params.InputProps,
                                                style: {
                                                    borderRadius: '8px',
                                                    borderColor: 'rgba(227, 227, 227, 1)',
                                                },
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: "20px" }}></div>
                {/* 
        <div style={{ height: "20px" }}></div> */}
                <Grid container spacing={2}>

                    {/* <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  value={formik.values.state}
                  onChange={(_, value) =>
                    formik.setFieldValue("state", value?.value)
                  }
                  id="combo-box-state"
                  options={[
                    { label: "Alberta", value: "Alberta" },
                    { label: "British Columbia", value: "British Columbia" },
                    { label: "Manitoba", value: "Manitoba" },
                    { label: "New Brunswick", value: "New Brunswick" },
                    { label: "Newfoundland and Labrador", value: "Newfoundland and Labrador" },
                    { label: "Nova Scotia", value: "Nova Scotia" },
                    { label: "Ontario", value: "Ontario" },
                    { label: "Prince Edward Island", value: "Prince Edward Island" },
                    { label: "Quebec", value: "Quebec" },
                    { label: "Saskatchewan", value: "Saskatchewan" },
                    { label: "Northwest Territories", value: "Northwest Territories" },
                    { label: "Nunavut", value: "Nunavut" },
                    { label: "Yukon", value: "Yukon" },
                  ]}
                  renderInput={(params) => (

                    <>

                      <Typography component="label" htmlFor="last_nameaddress" sx={labelStyles}>
                        State
                      </Typography>
                      <TextField
                        {...params}
                        // label="State"
                        helperText={formik.errors.state}
                        error={Boolean(formik.errors.state)}
                        placeholder="ex. Alberta"
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            borderRadius: "8px",
                            borderColor: "rgba(227, 227, 227, 1)",
                          },
                        }}
                      />

                    </>
                  )}
                />
              </FormControl>
            </Grid> */}

                    {/* <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                value={formik.values.state || state}
                                onChange={(_, value) => formik.setFieldValue('state', value?.value)}
                                id="combo-box-state"
                                options={[
                                    { label: "Alberta", value: "Alberta" },
                                    { label: "British Columbia", value: "British Columbia" },
                                    { label: "Manitoba", value: "Manitoba" },
                                    { label: "New Brunswick", value: "New Brunswick" },
                                    { label: "Newfoundland and Labrador", value: "Newfoundland and Labrador" },
                                    { label: "Nova Scotia", value: "Nova Scotia" },
                                    { label: "Ontario", value: "Ontario" },
                                    { label: "Prince Edward Island", value: "Prince Edward Island" },
                                    { label: "Quebec", value: "Quebec" },
                                    { label: "Saskatchewan", value: "Saskatchewan" },
                                    { label: "Northwest Territories", value: "Northwest Territories" },
                                    { label: "Nunavut", value: "Nunavut" },
                                    { label: "Yukon", value: "Yukon" },
                                ]}
                                renderInput={(params) => (
                                    <>
                                        <Typography component="label" htmlFor="last_nameaddress" sx={labelStyles}>
                                            State
                                        </Typography>
                                        <TextField
                                            {...params}
                                            helperText={formik.errors.state}
                                            error={Boolean(formik.errors.state)}
                                            placeholder="ex. Alberta"
                                            InputProps={{
                                                ...params.InputProps,
                                                style: {
                                                    borderRadius: '8px',
                                                    borderColor: 'rgba(227, 227, 227, 1)',
                                                },
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </FormControl>
                    </Grid>
 */}



                    <Grid item xs={12}>
                        <Typography component="label" htmlFor="subject" sx={labelStyles}>
                            Subject
                        </Typography>
                        <TextField
                            fullWidth
                            placeholder="ex. Calgary"
                            id="subject"
                            name="subject"
                            variant="outlined"
                            value={formik.values.subject} // Use the city from API or the formik value
                            onChange={(e) => {
                                const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Remove digits and special characters
                                formik.setFieldValue('subject', value);
                            }}
                            error={Boolean(formik.errors.subject)}
                            helperText={formik.errors.subject}
                            InputProps={{
                                style: {
                                    borderRadius: '8px',
                                    borderColor: 'rgba(227, 227, 227, 1)',
                                },
                            }}
                        />
                    </Grid>

                    {/* <Grid item xs={6}>

              <Typography component="label" htmlFor="zipcode" sx={labelStyles}>
                Zip Code
              </Typography>

              <TextField
                fullWidth
                id="zipcode"
                // label="Zip Code"
                placeholder="ex. T5A 0A1"
                name="zipcode"
                variant="outlined"
                value={formik.values.zipcode}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.zipcode)}
                helperText={formik.errors.zipcode}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "rgba(227, 227, 227, 1)",
                  },
                }}
              />
            </Grid> */}
                </Grid>
                <div style={{ height: "20px" }}></div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography component="label" htmlFor="messages" sx={labelStyles}>
                            Messages
                        </Typography>
                        <TextField
                            fullWidth
                            placeholder="Type your message here..."
                            id="messages"
                            name="messages"
                            variant="outlined"
                            multiline
                            rows={4} // Number of visible rows
                            value={formik.values.messages} // Use the message from API or the formik value
                            onChange={(e) => {
                                const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Remove digits and special characters
                                formik.setFieldValue('messages', value);
                            }}
                            error={Boolean(formik.errors.messages)}
                            helperText={formik.errors.messages}
                            InputProps={{
                                style: {
                                    borderRadius: '8px',
                                    borderColor: 'rgba(227, 227, 227, 1)',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <div style={{ height: "20px" }}></div>
                {formik.values.type_query === 'Other' && (
    <Grid item xs={12}>
        <Typography component="label" htmlFor="reason" sx={labelStyles}>
            Reason
        </Typography>
        <TextField
            fullWidth
            placeholder="ex. Calgary"
            id="reason"
            name="reason"
            variant="outlined"
            value={formik.values.reason}
            onChange={(e) => {
                const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Remove digits and special characters
                formik.setFieldValue('reason', value);
            }}
            error={Boolean(formik.errors.reason)}
            helperText={formik.errors.reason}
            InputProps={{
                style: {
                    borderRadius: '8px',
                    borderColor: 'rgba(227, 227, 227, 1)',
                },
            }}
        />
    </Grid>
)}



                <div style={{ height: "20px" }}></div>

                <ContinueButton type="submit" disabled={false} >
                    Save
                </ContinueButton>
                {/* <ContinueButton
          type="button"
          onClick={() => {
            formik.resetForm();
            resetZipCode();
            
          }}
        >
          Add New Address
        </ContinueButton> */}
                {/* <Divider /> */}


            </WidthAdjuster>
        </form>
    );
}

export default SupportForm;
