import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const ParentWrapper = styled.div`
  display: flex;
  /* justify-content: center; */
  /* align-items: flex-start; */
  flex-direction: column;
  // margin-right: 5%;
      margin-top:17px;
`;

const Heading = styled.div`
  font-family: "Inter";
  font-weight: 600;
  color: white;
  font-size: 24px;
  line-height: 29.05px;
  @media (max-width: 600px) {
    font-size: 18px;
  line-height: 29.05px;
  }
`;
const Title = styled.div`
  font-family: "Inter";
  font-weight: 400;
  color: white;
  font-size: 18px;
  line-height: 32px;
  text-decoration:underline;


    @media (max-width: 1287px) {
    font-size: 16px;

  }

    @media (max-width: 600px) {
    font-size: 14px;
  line-height: 29.05px;
  }
`;
function HeadingWithLinks({ heading, links }) {
  return (
    <ParentWrapper>
      <Heading>{heading}</Heading>
      {links.map((item) => {
         
       return <Link onClick={()=>{
        window.scrollTo(0, 0);
       }} key={item.linkName} style={{textDecoration:"none"}} to={item.link}><Title>{item.linkName}</Title></Link>;
      })}
    </ParentWrapper>
  );
}

export default HeadingWithLinks;
