import React, { useState } from "react"
import styled from "@emotion/styled"
import SelectAddress from "../../components/OrderCheckout/SelectAddress";
import { useNavigate } from "react-router-dom";
import Checkout from "../../components/OrderCheckout/Checkout";
import { setUserDetails } from "../../store/reducer/reducer";
import { get, post } from "../../api_helper/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
const BodyWrapper = styled.div`
min-height: 20%;
background:white ;
width: 100%;
padding-bottom: 40px;
`

function OrderCheckout(){
    const navigation = useNavigate()

    
  const dispatch = useDispatch();
    const [currentTab,setCurrentTab] = useState(1)
    useEffect(() => {
        const fetchUserDetails = async () => {
          try {
            const { userProfile } = await get("/api/auth/profile-info");
            dispatch(setUserDetails(userProfile || {}));
          } catch (e) {
            console.error("Error fetching user details:", e);
          }
        };
    
        fetchUserDetails();
      }, [dispatch]);	
    return (
        <BodyWrapper>
            {currentTab ===2?<Checkout currentTab={currentTab} setCurrentTab={setCurrentTab}/>:null}
            {currentTab ===1?<SelectAddress currentTab={currentTab} setCurrentTab={setCurrentTab}/>:null}
        </BodyWrapper>
    )
}

export default OrderCheckout