import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import WishlistItem from "../../components/Wishlist/WishlistItem";
import { useSelector ,useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../api_helper/api_helper";
import EmptyWishlist from "../../assets/images/empty_wishlist.svg";
import DeleteWishListItem from "../../components/Wishlist/DeleteItem";
import { toast } from "react-toastify";
import { setUserDetails } from "../../store/reducer/reducer";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import SelectTypeOfShipment from "../../components/Products/SelectTypeOfShipment";

const WidthAdjuster = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;
  margin-bottom:64px;
  max-width:1525px;
  @media (max-width: 600px) {
    width: 97%;
  }
`;
const ParentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const HeadingBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  @media (max-width: 600px) {
    width: 97%;
  }
`;
const Heading = styled.div`
  font-family: "Inter";
  font-size: 36px;
  color : "#3E4D50";
  font-weight: 600;
   color:#3E4D50;
  line-height: 43.57px;
  @media (max-width: 600px) {
    font-size: 26px;
  }
`;

const CustomPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    color: #00B4D8;
    border-color: #00B4D8;
  }
  .MuiPaginationItem-root.Mui-selected {
    background-color: #00B4D8;
    color: white;
    width:36px;
    height:36px;
    border-radius:19px;
    font-size:16px;
  }
  .MuiPaginationItem-ellipsis {
    color: #00B4D8;
  }
  .MuiPaginationItem-icon {
    color: #00B4D8;
  }
`;

const PaginationInfo = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color:#7B7B7B;
`;

const ItemsCount = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
     color:#3E4D50;
  @media (max-width: 600px) {
    font-size: 17px;
  }
`;

const WishlistWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  min-height: 500px;
  gap:10px;
  
  
`;

const PaginationUI = styled.div`
    margin: 74px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    padding: 10px 14px;
    border-top: 1.4px solid rgba(0, 0, 0, 0.2);

         @media (max-width: 600px) {
    width:  80% ;

  }

`;

// const WishlistWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   margin-top: 30px;
//   min-height: 500px;
//      gap: 23px;
//   min-height: 500px;
//   max-height: 500px;
// overflow-y: auto;
// overflow-x: hidden;
//   padding: 39px 0px;
// `;

function Wishlist() {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const user = useSelector((state) => state.general.user);
  const [wishlistItems, setWishlistItem] = useState([]);
  const navigate = useNavigate("/");
  const [currentPage, setCurrentPage] = useState(1);
  console.log("Page Wish Items", wishlistItems)
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { userProfile } = await get("/api/auth/profile-info");
        dispatch(setUserDetails(userProfile || {}));
      } catch (e) {
        console.error("Error fetching user details:", e);
      }
    };

    fetchUserDetails();
  }, [dispatch]);	
  const itemsPerPage = 5;

  const totalPages = Math.ceil(wishlistItems.length / itemsPerPage);
  const indexOfLastWishlist = currentPage * itemsPerPage;
  const indexOfFirstWishlist = indexOfLastWishlist - itemsPerPage;
  const currentProducts = wishlistItems.slice(indexOfFirstWishlist, indexOfLastWishlist);
  console.log("Current Wishlist", wishlistItems)

  

  async function removeFromWishlist(ProductId) {

    if (user) {
      try {
        await get(`/api/wishlist/remove/${ProductId}`);
  
        toast.success("Removed from wishlist");

        getUserWishlistItems();
      } catch (e) {
        toast.error(e?.response?.data?.error || "something went wrong");
      }
    }

    else {

      try {
        // For guest users, manage wishlist using localStorage
        const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];

        // Find the index of the item to remove from the wishlist
        const itemIndex = storedWishlist.findIndex(item => item.id === ProductId);

        if (itemIndex > -1) {
          // Remove the item from the wishlist
          storedWishlist.splice(itemIndex, 1);

          // Update the wishlist in localStorage
          localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
          setWishlistItem(storedWishlist)
          toast.success("Removed from wishlist");


        } else {
          toast.error("Item not found in wishlist");
        }
      } catch (e) {
        toast.error(e?.response?.data?.error || "Something went wrong");
        console.log(e);
      }

    }
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);

    window.scrollTo(0, 0); // Scroll to the top when the component is mounted

  };

  // async function removeFromWishlist(bool) {
  // try {
  //   // For guest users, manage wishlist using localStorage
  //   const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];

  //   // Find the index of the item to remove from the wishlist
  //   const itemIndex = storedWishlist.findIndex(item => item.id === selectedItem?.id);

  //   if (itemIndex > -1) {
  //     // Remove the item from the wishlist
  //     storedWishlist.splice(itemIndex, 1);

  //     // Update the wishlist in localStorage
  //     localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
  //     setWishlistItem(storedWishlist)
  //     toast.success("Removed from wishlist");


  //   } else {
  //     toast.error("Item not found in wishlist");
  //   }
  // } catch (e) {
  //   toast.error(e?.response?.data?.error || "Something went wrong");
  //   console.log(e);
  // }
  // }

  async function getUserWishlistItems() {
    if (user) {
      try {
        const items = await post("/api/wishlist", {});
        console.log("Fetched wishlist from API:", items);
        setWishlistItem(items?.wishlist?.wishlist_items || []);
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    }
  }

  console.log("Items Wish", wishlistItems)
  async function syncGuestWishlistToUser() {
    try {
      // Fetch guest wishlist from localStorage
      const guestWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];

      if (guestWishlist.length > 0) {
        for (const item of guestWishlist) {
          try {
            // Call the add-to-wishlist API for each item
            await get(
              `/api/wishlist/add/${item?.id}${item?.contain_variants ? `/${item?.product_variants[0].id}` : ""
              }`
            );
            console.log(`Item ${item.id} added to wishlist successfully.`);
          } catch (error) {
            // Log any error but don't show a failure message to the user
            console.log(`Item ${item.id} already exists in the wishlist or failed to add.`);
          }
        }

        // Clear the guest wishlist from localStorage after successful sync
        localStorage.removeItem("wishlist");
        toast.success("Guest wishlist items merged with your account!");
      }
    } catch (error) {
      console.error("Error syncing guest wishlist:", error);
      toast.error("Failed to merge guest wishlist. Please try again.");
    }
  }


  // useEffect(() => {
  //   if (!user) {
  //     // For guest users, load the wishlist from localStorage
  //     const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  //     console.log("Loaded wishlist from localStorage:", storedWishlist); // Log to check what we get from localStorage
  //     setWishlistItem(storedWishlist);
  //   } else {
  //     // For logged-in users, fetch wishlist from server
  //     getUserWishlistItems();
  //   }
  // }, [user]);
  useEffect(() => {
    if (!user) {
      // For guest users, load the wishlist from localStorage
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      setWishlistItem(storedWishlist);
    } else {
      // Sync guest wishlist to user's account and fetch updated wishlist
      syncGuestWishlistToUser()
        .then(() => getUserWishlistItems())
        .catch((error) => console.error("Error syncing wishlist:", error));
    }
  }, [user]);
  // useEffect(() => {
  //   if (!user) {
  //     // For guest users, load the wishlist from localStorage
  //     const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  //     console.log("Loaded wishlist from localStorage:", storedWishlist);
  //     setWishlistItem(storedWishlist);
  //   } else {
  //     // For logged-in users, treat it like guest users (use localStorage as well)
  //     const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  //     console.log("Loaded wishlist for logged-in user (from localStorage):", storedWishlist);
  //     setWishlistItem(storedWishlist);
  //   }
  // }, [user]);


  return (
    <WidthAdjuster>
      <DeleteWishListItem
        removeFromWishlist={removeFromWishlist}
        setShowModal={setShowModal}
        showModal={showModal}
        selectedItem={selectedItem}
      />
      <ParentWrapper>
        <HeadingBox>
          <Heading>My Wishlist</Heading>
          <ItemsCount>{`(${wishlistItems.length || 0} items)`}</ItemsCount>
        </HeadingBox>
      </ParentWrapper>

      <WishlistWrapper>
        {currentProducts.length ? (
          currentProducts.map((item) => {
            return (
              <WishlistItem
                setShowModal={setShowModal}
                setSelectedItem={setSelectedItem}
                getUserWishlistItems={getUserWishlistItems}
                wishlistItems={wishlistItems}
                setWishlistItem={setWishlistItem}
                key={item?.id}
                item={item}
                removeFromWishlist={removeFromWishlist}
              />
            );
          })
        ) : (
          <img alt="wishlist" src={EmptyWishlist} />
        )}
      </WishlistWrapper>


      {wishlistItems?.length > 5 && (
        <PaginationUI>
          <PaginationInfo>
            {`Page ${currentPage} of ${totalPages}`}
          </PaginationInfo>
          <Stack spacing={2}>
            <CustomPagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                "& .MuiPaginationItem-root.Mui-selected": {
                  borderRadius: "6px !important",
                },


              }}
            />
          </Stack>
        </PaginationUI>
      )}

      <SelectTypeOfShipment removeFromWishlist={removeFromWishlist}></SelectTypeOfShipment>
    </WidthAdjuster>
  );
}

export default Wishlist;
