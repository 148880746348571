import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "@emotion/styled";
import "react-phone-input-2/lib/style.css";
import CloseModal from "../Auth/CloseModal";
import CloseStatus from "../Auth/CloseStatus";
import Box from "../../assets/images/Icon1";
import Truck from "../../assets/images/Truck";
import Packing from "../../assets/images/Icon2";
import Airplane from "../../assets/images/Icon3";
import Delivered from "../../assets/images/Icon4";
import InfoIcon from "@mui/icons-material/Info";  // Import the Info icon from Material-UI
import Process from "../../assets/images/Process";
import Recieve from "../../assets/images/Recieve";
import Shipped from "../../assets/images/Shipped";
import Reached from "../../assets/images/Reached";
import Done from "../../assets/images/Done";
import Hold from "../../assets/images/Hold";
import Hold_status from "../../assets/images/Hold_status.png";
import Cancelled_status from "../../assets/images/Cancelled_status.png";
import Cancelled from "../../assets/images/Cancelled";
import "./ShipmentTypeCard.css"
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';



// New style for the info icon
const InfoIconWrapper = styled.div`
  cursor: pointer;
  font-size: 0px;
  color: red;
  margin-left: 8px;

`;

const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  width: clamp(296px, 50%, 400px);
  max-height: 90vh;
  padding: 24px;
  overflow-y: auto;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
`;

const Heading = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #111827;
`;

const TimelineContainer = styled.div`
  position: relative;
  margin-left: 12px;
`;

const VerticalLine = styled.div`
  position: absolute;
  left: 6px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #E5E7EB;
`;

const StatusItem = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 12px 0;
  gap: 32px;
`;

// const StatusDot = styled.div`
//   width: 20px;
//   height: 20px;
//   border-radius: 50%;
//   border: 1px solid 
//     ${({ active, status }) =>
//     status === "HOLD" || status === "CANCELLED" ? "red" : active ? "rgba(28, 210, 156, 1)" : "#E5E7EB"};
//   background-color: 
//     ${({ completed, status }) =>
//     status === "HOLD" || status === "CANCELLED" ? "red" : completed ? "none" : "white"};
//   position: absolute;
//   left: 6px;
//   top: 22px;
//   transform: translateX(-50%);
//   z-index: 1;
// `;

const StatusDot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid 
    ${({ active, status }) =>
    status === "HOLD" || status === "CANCELLED" ? "red" : active ? "rgba(28, 210, 156, 1)" : "#E5E7EB"};
  background-color: 
    ${({ completed, status }) =>
    status === "HOLD" || status === "CANCELLED" ? "none" : completed ? "none" : "white"};
  position: absolute;
  left: 6px;
  top: 22px;
  transform: translateX(-50%);
  z-index: 1;

  // Add a dot in the center
  &::before {
    content: "•";
    color: ${({ active, status }) => 
      status === "HOLD" || status === "CANCELLED" ? "red" : "rgba(28, 210, 156, 1)"};
    font-size: 20px;
    position: absolute;
    top: 40%;
    left: 53.4%;
    transform: translate(-50%, -50%);
    display: ${({ active }) => (active ? "block" : "none")};
  }
`;


const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 16px;
  justify-content:space-between;
  width:100%;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  
  svg {
    fill: ${({ active, status }) =>
    status === "HOLD" || status === "CANCELLED" ? "red" : active ? "none" : "none"};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
`;

const StatusText = styled.span`
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: ${({ active, status }) =>
    status === "HOLD" || status === "CANCELLED" ? "red" : active ? "rgba(28, 210, 156, 1)" : "#6B7280"};
`;

const DateText = styled.span`
  font-size: 12px;
  color: #6B7280;
  margin-top: 2px;
`;

const HoldReasonModal = styled.div`
  padding: 12px;
  background: white;
  border-radius: 10px;
  max-width: 300px;
  margin:10px auto;
  position:relative;
`;

function OrderStatusModal({
  selectedOrder,
  setSelectedOrder,
  displayModal,
  setDisplayModal,
}) {
  const [holdModalOpen, setHoldModalOpen] = useState(false);
  const [cancelModalOpen, setcancelModalOpen] = useState(false);


  const statuses = [
    { status: "PLACED", Icon: Box },
    { status: "READY_TO_DISPATCH", Icon: Truck },
    { status: "PROCESSING", Icon: Process },
    { status: "RECEIVED", Icon: Recieve },
    { status: "SHIPPED", Icon: Shipped },
    { status: "REACHED_TO_LOCAL_PARTNER", Icon: Reached },
    { status: "DELIVERED", Icon: Done },
  ];

  if (selectedOrder?.order_status === "HOLD") {
    const currentIndex = statuses.findIndex(s => s.status === selectedOrder?.order_status);
    if (currentIndex === -1) {
      statuses.splice(1, 0, { status: "HOLD", Icon: Hold });
    }
  }

  if (selectedOrder?.order_status === "CANCELLED") {
    const currentIndex = statuses.findIndex(s => s.status === selectedOrder?.order_status);
    if (currentIndex === -1) {
      statuses.splice(1, 0, { status: "CANCELLED", Icon: Cancelled });
    }
  }

  const isCompleted = (status) => {
    const currentIndex = statuses.findIndex(s => s.status === selectedOrder?.order_status);
    const statusIndex = statuses.findIndex(s => s.status === status);
    return statusIndex < currentIndex;
  };

  const isActive = (status) => {
    return status === selectedOrder?.order_status;
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  return (
    <Modal
      open={displayModal}
      onClose={() => {
        setDisplayModal(false);
        setSelectedOrder(null);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <LoginFormWrapper>
        <CloseModal
          closeFn={() => {
            setDisplayModal(false);
            setSelectedOrder(null);
          }}
        />
        <Heading>Orders #{selectedOrder?.display_id}</Heading>
        <TimelineContainer>
          <VerticalLine />
          {statuses.map(({ status, Icon }) => {
            const completed = isCompleted(status);
            const active = isActive(status);
            return (
              <StatusItem key={status}>
                <StatusDot completed={completed} active={completed || active} status={status} />
                <ContentWrapper>
                  <IconWrapper active={completed || active} status={status}>
                    <Icon
                      color={status === "HOLD" || status === "CANCELLED" ? "red" : completed || active ? "rgba(28, 210, 156, 1)" : "#6B7280"}
                    />
                  </IconWrapper>
                  <TextWrapper>
                    <StatusText active={completed || active} status={status}>
                      {status.replace(/_/g, " ")}
                    </StatusText>
                    {/* {status === "PLACED" && (
                      <DateText>
                        {formatDate(selectedOrder?.createdAt)}
                      </DateText>
                    )} */}
                    {status === "HOLD" && (
                      <InfoIconWrapper onClick={() => setHoldModalOpen(true)}>
                        <InfoIcon />
                      </InfoIconWrapper>
                    )}

                    {status === "CANCELLED" && (
                      <InfoIconWrapper onClick={() => setcancelModalOpen(true)}>
                        <InfoIcon />
                      </InfoIconWrapper>
                    )}
                  </TextWrapper>
                </ContentWrapper>
              </StatusItem>
            );
          })}
        </TimelineContainer>

        {/* Modal for Hold Reason */}
        {/* <Dialog open={holdModalOpen} onClose={() => setHoldModalOpen(false)} className="ModalPopUp">

          <CloseStatus
            closeFn={() => {
              setHoldModalOpen(false);

            }}
          />
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px", gap: "18px" }}>

            <img src={Hold_status} alt="Hold Status" style={{ width: '100px', height: '100px' }} />
            <span style={{ color: "#FF0000", fontWeight: "600", fontSize: "28px", fontFamily: "Inter" }}>Parcel on Hold</span>

          </div>

          <DialogContent >
            <Typography variant="body1" color="textSecondary">
            {selectedOrder?.cancellation_or_hold_reason || "This order is on hold due to some issues."}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHoldModalOpen(false)} color="error" >Okay</Button>
          </DialogActions>
        </Dialog> */}

        <Dialog
          open={holdModalOpen}
          onClose={() => setHoldModalOpen(false)}
          sx={{
            '& .MuiDialog-paper': {
              maxWidth: '490px !important',
              height: '400px !important',
              width: '600px !important',
              borderRadius: '16px !important',
            },
          }}
        >
          <CloseStatus
            closeFn={() => {
              setHoldModalOpen(false);
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '20px',
              gap: '18px',
            }}
          >
            <img src={Hold_status} alt="Hold Status" style={{ width: '100px', height: '100px' }} />
            <span
              style={{
                color: '#FF0000',
                fontWeight: '600',
                fontSize: '28px',
                fontFamily: 'Inter',
              }}
            >
              Parcel on Hold
            </span>
          </div>

          <DialogContent
            sx={{
              display: 'flex !important',
              justifyContent: 'center !important',
              alignItems: 'center !important',
              '& .MuiTypography-root': {
                color: '#424545 !important',
                fontWeight: '500 !important',
              },
            }}
          >
            <Typography variant="body1" color="textSecondary">
              {selectedOrder?.cancellation_or_hold_reason || 'This order is on hold due to some issues.'}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              margin: 'auto !important',
              '& .MuiButton-root': {
                color: 'white !important',
                backgroundColor: '#FF0000 !important',
                fontFamily: 'Inter !important',
                borderRadius: '4px !important',
                marginBottom: '10px !important',
                padding: '6px 37px !important',
              },
            }}
          >
            <Button onClick={() => setHoldModalOpen(false)} color="error">
              Okay
            </Button>
          </DialogActions>
        </Dialog>



        <Dialog
  open={cancelModalOpen}
  onClose={() => setcancelModalOpen(false)}
  sx={{
    '& .MuiDialog-paper': {
      maxWidth: '490px !important',
      height: '400px !important',
      width: '600px !important',
      borderRadius: '16px !important',
    },
  }}
>
  <CloseStatus
    closeFn={() => {
      setcancelModalOpen(false);
    }}
  />
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '20px',
      gap: '18px',
    }}
  >
    <img src={Cancelled_status} alt="Cancelled Status" style={{ width: '100px', height: '100px' }} />
    <span
      style={{
        color: '#FF0000',
        fontWeight: '600',
        fontSize: '28px',
        fontFamily: 'Inter',
      }}
    >
      Order Cancelled
    </span>
  </div>

  <DialogContent
    sx={{
      display: 'flex !important',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      '& .MuiTypography-root': {
        color: '#424545 !important',
        fontWeight: '500 !important',
      },
    }}
  >
    <Typography variant="body1" color="textSecondary">
      {selectedOrder?.cancellation_or_hold_reason || 'This order is cancelled due to some issues.'}
    </Typography>
  </DialogContent>
  <DialogActions
    sx={{
      margin: 'auto !important',
      '& .MuiButton-root': {
        color: 'white !important',
        backgroundColor: '#FF0000 !important',
        fontFamily: 'Inter !important',
        borderRadius: '4px !important',
        marginBottom: '10px !important',
        padding: '6px 37px !important',
      },
    }}
  >
    <Button onClick={() => setcancelModalOpen(false)} color="error">
      Okay
    </Button>
  </DialogActions>
</Dialog>


      </LoginFormWrapper>
    </Modal>
  );
}

export default OrderStatusModal;
