import React, { useState } from "react";
import Modal from "@mui/material/Modal";

import { useDispatch, useSelector } from "react-redux";
import {
  addUserInfo,
  isRegistering,
  showLoginModal,
  showOtpModal,
  showSignupModal,
  tempRegistrationData,
} from "../../store/reducer/reducer";
import { useEffect } from "react";
import styled from "@emotion/styled";
import { post } from "../../api_helper/api_helper";
import { useFormik } from "formik";
import CountryCodes from "../../assets/json/countrycode.json";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "react-phone-input-2/lib/style.css";
import CloseModal from "./CloseModal";
import { IconButton, Typography, Box } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import './validation.css'

const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border-radius: 16px;
 width: clamp(450px, 30%, 636px);
  padding: 20px;
  box-sizing: border-box;
      max-height: 95vh;
     overflow-y: auto; /* Scroll content if needed */
  @media (max-width: 800px) {
    width: 90%;
  }

   @media (min-width: 600px) {
    overflow-y: auto; /* Enable vertical scroll */
  }

  // @media (max-width: 599px) {
  //   max-height: unset;
  //   overflow-y: unset;
  // }

   @media (max-width: 400px) {
  padding: 10px 13px;
  }
  position: relative;
`;

const WelcomeShipora = styled.div`
  font-family: "Inter";
  font-size: 36px;
  color: #003E4C;
  font-weight: 700;
  margin-top:0;
  margin-bottom:32px;

 
`;
const SignupLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BlackText = styled.div`
  color: #858b8b;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
`;
const Bluetext = styled.div`
  color: #00b4d8;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
  cursor:pointer;
`;
const Heading = styled.div`
  font-family: "Inter";
  font-size: 41px;
  line-height: 33.89px;
  font-weight: 600;
  margin-bottom: 45px;
  color:#424545;

   @media (max-width: 400px) {
  margin-bottom: 4px;
  font-size: 25px;;
  }
`;

function SignUpModal() {
  // console.log(CountryCodes)
  const matches = useMediaQuery('(max-width:600px)');




  const [showPassword, setShowPassword] = useState(false);

  const displayModal = useSelector((state) => state.general.showSignupModal);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (values) => {
    try {
      setLoading(true);

      await post("/api/auth/registration", {
        email: values.emailAddress,
        password: values.password,
        confirm_password: values.password,
        mobilenumber: values.phone_number,
        first_name: values.first_name,
        last_name: values.last_name,
        country_code: values.country_code,
      });
      toast.success("OTP sent successfully!");
      setLoading(false);
      dispatch(showSignupModal(false));
      dispatch(showOtpModal(true));
      dispatch(tempRegistrationData(values));
      dispatch(isRegistering(true));
      formik.resetForm();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
    }
  };

  useEffect(() => {
    if (displayModal) {
      // Disable scrolling by applying overflow hidden to the body
      document.documentElement.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling by resetting overflow to auto
      document.documentElement.style.overflow = 'auto';
    }

    // Cleanup function to ensure scrolling is restored when the component is unmounted
    return () => {
      document.documentElement.style.overflow = 'auto';
    };
  }, [displayModal]); // Trigger effect whenever the isOpen state chang
  console.log("True or False",displayModal)

  const ValidationSchema = Yup.object({
    phone_number: Yup.string()
      .strict(true) // Strict mode will prevent automatic string coercion
      .required('Phone number is required!')
      .matches(/^\d+$/, 'Phone number must contain only digits') // Ensures the phone number contains only digits
      .length(10, 'Phone number must be exactly 10 digits'), // Ensures the number is exactly 10 digits

    country_code: Yup.string()
      .strict(true)
      .required("Country code is required!"),
    // first_name: Yup.string().strict(true).required("First name is required!"),
    first_name: Yup.string()
      .matches(/^[A-Za-z\s]*$/, "Digits are not allowed in the first name")
      .required("First name is required"),
    // last_name: Yup.string().strict(true).required("Last name is required!"),
    last_name: Yup.string()
      .matches(/^[A-Za-z\s]*$/, "Digits are not allowed in the last name")
      .required("Last name is required"),
    password: Yup.string()
      .strict(true)
      .required("Password is required!")
      .test("password", "Invalid password", (value) => {
        if (!value || value.length < 6) {
          throw new Yup.ValidationError(
            "Password should be at least 6 characters long",
            null,
            "password"
          );
        }
        if (!/[A-Z]/.test(value)) {
          throw new Yup.ValidationError(
            "Password should contain at least one uppercase letter",
            null,
            "password"
          );
        }
        if (!/[a-z]/.test(value)) {
          throw new Yup.ValidationError(
            "Password should contain at least one lowercase letter",
            null,
            "password"
          );
        }
        if (!/\d/.test(value)) {
          throw new Yup.ValidationError(
            "Password should contain at least one digit",
            null,
            "password"
          );
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
          throw new Yup.ValidationError(
            "Password should contain at least one special character",
            null,
            "password"
          );
        }
        return true;
      }),
    emailAddress: Yup.string()
      .strict(true)
      .matches(
        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        "Email must be in lowercase"
      )
      .email("Invalid Email Address!")
      .required("Email Address is required!"),
  });
  const formik = useFormik({
    initialValues: {
      emailAddress: "",
      password: "",
      first_name: "",
      last_name: "",
      country_code: "",
      phone_number: "",
    },
    onSubmit,
    validationSchema: ValidationSchema,
    validateOnChange: false, // Disable validation on change
    validateOnBlur: false,    // Disable validation on blur
  });

  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '13px',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400,
      fontSize: '16px',
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'primary.main',
      },
    },
    '& .MuiInputBase-input': {
      padding: '16.5px 14px',
    },

    '@media (max-width: 400px)': {
                          '& .MuiInputBase-input': {
                            padding: '11px 14px'
                          }
                        }



  };

  const labelStyles = {
    display: 'block',
    marginBottom: '5px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '21px',
    fontWeight: 500,
    color: "#5E6363",





  };
  return (
    <>
      <Modal
        open={displayModal}
        onClose={() => {
          dispatch(showSignupModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LoginFormWrapper className="CustomFormWrapper">
          <CloseModal
            closeFn={() => {
              dispatch(showSignupModal(false));
            }}
          />

          {/* {matches ? null : (
            <WelcomeShipora> Welcome To Shipora</WelcomeShipora>
          )} */}




          <div>
            <React.Fragment>
              {matches ? null : (
                <WelcomeShipora> Welcome To Shipora</WelcomeShipora>
              )}
              <Heading>Sign Up</Heading>

              <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Box sx={{ width: '100%', maxWidth: 500, margin: 'auto' }}>
                  <Box sx={{ marginBottom: '0px' }}>
                    <Typography
                      component="label"
                      htmlFor="first-name-input"
                      sx={{
                        display: 'block',
                        marginBottom: '5px',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '21px',
                        fontWeight: 500,
                        color: '#5E6363',

                        '@media (max-width: 400px)': {
                          fontSize: '16px', // Adjust font size for small screens

                        },


                      }}
                    >
                      First Name
                    </Typography>
                    <TextField
                      id="first-name-input"
                      variant="outlined"
                      color="primary"
                      type="text"
                      name="first_name"
                      placeholder="ex. John"
                      value={formik.values.first_name}
                      onChange={(e) => {
                        // Allow only letters and spaces in first name
                        const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Regex to remove numbers and special characters
                        formik.setFieldValue('first_name', value);
                      }}
                      onBlur={formik.handleBlur}
                      error={Boolean(formik.errors.first_name)}
                      helperText={formik.touched.first_name && formik.errors.first_name}
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '13px',
                          fontFamily: 'Inter, sans-serif',
                          fontWeight: 400,
                          fontSize: '16px',
                          '& fieldset': {
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                          },
                          '&:hover fieldset': {
                            borderColor: 'rgba(0, 0, 0, 0.87)',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'primary.main',
                          },
                        },
                        '& .MuiInputBase-input': {
                          padding: '16.5px 14px',
                        },

                        '@media (max-width: 400px)': {
                          '& .MuiInputBase-input': {
                            padding: '11px 14px'
                          }
                        }

                      }}
                    />
                  </Box>

                  <Box sx={{ marginTop: '13px' }}>
                    <Typography
                      component="label"
                      htmlFor="last-name-input"
                      sx={{
                        display: 'block',
                        marginBottom: '5px',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '21px',
                        fontWeight: 500,
                        color: '#5E6363',

                        '@media (max-width: 400px)': {
                          fontSize: '16px', // Adjust font size for small screens

                        },
                      }}
                    >
                      Last Name
                    </Typography>
                    <TextField
                      id="last-name-input"
                      variant="outlined"
                      color="primary"
                      type="text"
                      name="last_name"
                      placeholder="ex. Doe"
                      value={formik.values.last_name}
                      onChange={(e) => {
                        // Allow only letters and spaces in last name
                        const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Regex to remove numbers and special characters
                        formik.setFieldValue('last_name', value);
                      }}
                      onBlur={formik.handleBlur}
                      error={Boolean(formik.errors.last_name)}
                      helperText={formik.touched.last_name && formik.errors.last_name}
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '13px',
                          fontFamily: 'Inter, sans-serif',
                          fontWeight: 400,
                          fontSize: '16px',
                          '& fieldset': {
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                          },
                          '&:hover fieldset': {
                            borderColor: 'rgba(0, 0, 0, 0.87)',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'primary.main',
                          },
                        },
                        '& .MuiInputBase-input': {
                          padding: '16.5px 14px',
                        },

                        '@media (max-width: 400px)': {
                          '& .MuiInputBase-input': {
                            padding: '11px 14px'
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>

                {/* <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        onChange={(_, value) =>
                          formik.setFieldValue("country_code", value.dial_code)
                        }
                        id="combo-box-demo"
                        options={CountryCodes.map((item) => ({
                          ...item,
                          label: `${item.flag} (${item.dial_code}) - ${item.name}`,
                          value: item.dial_code,
                        }))}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country Code"
                            helperText={formik.errors.country_code}
                            error={Boolean(formik.errors.country_code)}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      id="phone-number"
                      label="Phone Number"
                      name="phone_number"
                      variant="outlined"
                      value={formik.values.phone_number}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.phone_number)}
                      helperText={formik.errors.phone_number}
                    />
                  </Grid>
                </Grid> */}
                <Box sx={{ width: '100%', maxWidth: 500, margin: 'auto', marginTop: "16px" }}>
                  <Typography
                    component="label"
                    htmlFor="phone-input"
                    sx={{
                      display: 'block',
                      marginBottom: '5px',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '21px',
                      fontWeight: 500,
                      color: '#5E6363',

                      '@media (max-width: 400px)': {
                        fontSize: '16px', // Adjust font size for small screens

                      },
                    }}
                  >
                    Mobile Number
                  </Typography>
                  <TextField
                    id="phone-input"
                    fullWidth
                    placeholder="ex. 8765453243"
                    variant="outlined"
                    value={formik.values.phone_number}
                    sx={{'@media (max-width: 400px)': {
                          '& .MuiInputBase-input': {
                            padding: '11px 14px'
                          }
                        }}}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                      if (value.length <= 10) {
                        formik.setFieldValue('phone_number', value); // Only set value if it's 10 digits or less
                      }
                    }}
                    name="phone_number"
                    error={Boolean(formik.errors.phone_number) || Boolean(formik.errors.country_code)}
                    helperText={formik.errors.phone_number || formik.errors.country_code || ' '}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Autocomplete

                            sx={{
                              '& .MuiAutocomplete-clearIndicator': {
                                display: 'none !important',
                              },
                              '& .MuiInputBase-input': {
                                minWidth: '60px !important',
                              },
                              '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                                minWidth: '66px !important',
                              },
                            }}


                            disablePortal
                            onChange={(_, value) =>
                              formik.setFieldValue('country_code', value?.dial_code || '')
                            }
                            options={CountryCodes}
                            getOptionLabel={(option) => `${option.flag} ${option.dial_code}`}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                placeholder="ex. +1"
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                  style: { width: '80px', fontSize: '0.875rem' }
                                }}


                              />
                            )}
                          />
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                              mx: 1,
                              height: 28,
                              alignSelf: 'center',
                              backgroundColor: 'rgba(0, 0, 0, 0.2)',
                            }}
                          />
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: '13px',
                        borderColor: 'rgba(227, 227, 227, 1)',
                      },
                    }}
                  />
                </Box>





                <Box sx={{ width: '100%', maxWidth: 500, margin: ' auto' }}>
                  <Box sx={{ marginBottom: '0px' }}>
                    <Typography component="label" htmlFor="email-input" sx={{
                      ...labelStyles,
                      '@media (max-width: 400px)': {

                        fontSize: '16px', // Adjust font size for small screens

                      },
                    }}>
                      Email Address
                    </Typography>
                    <TextField
                      id="email-input"
                      variant="outlined"
                      color="primary"
                      type="email"
                      name="emailAddress"
                      placeholder="ex.  john@johndoe.com"
                      value={formik.values.emailAddress}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(formik.errors.emailAddress)}
                      helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                      fullWidth
                      sx={inputStyles}
                    />
                  </Box>

                  <Box sx={{ marginTop: '16px' }}>
                    <Typography component="label" htmlFor="password-input" sx={{
                      ...labelStyles,
                      '@media (max-width: 400px)': {

                        fontSize: '16px', // Adjust font size for small screens

                      },
                    }}>
                      Password
                    </Typography>
                    <TextField
                      id="password-input"
                      variant="outlined"
                      color="primary"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="ex. Password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                      fullWidth
                      sx={inputStyles}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{
                      margin: "20px auto",
                      background: "#00B4D8",
                      color: "white",
                      borderRadius: "24px",
                      width: "80%",
                      height: "43px",
                      cursor: "pointer",
                    }}
                    variant="contained"
                    type="submit"
                    // Remove the disabled condition completely
                    disabled={false}
                  >
                    {loading ? <CircularProgress /> : `Sign Up`}
                  </Button>

                </div>
                <SignupLine>
                  <BlackText>Already have an account?</BlackText>
                  <Bluetext
                    onClick={() => {
                      dispatch(showLoginModal(true));
                      dispatch(showSignupModal(false));
                    }}
                  >
                    Sign In
                  </Bluetext>
                </SignupLine>
              </form>
            </React.Fragment>
          </div>
        </LoginFormWrapper>
      </Modal>
    </>
  );
}

export default SignUpModal;
