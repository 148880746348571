import React, { useState } from "react";
import styled from "@emotion/styled";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AirplanemodeActiveTwoToneIcon from "@mui/icons-material/AirplanemodeActiveTwoTone";
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
import EditNoteTwoToneIcon from "@mui/icons-material/EditNoteTwoTone";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { toast } from "react-toastify";
import { post } from "../../api_helper/api_helper";
import { useDispatch } from "react-redux";
import {
  selectedCartItem,
  showUpdateCartItemShipment,
} from "../../store/reducer/reducer";
import { formatDollars } from "../../helper/generalFunctions";

const BtnsBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 800px) {
    margin-top: 10px;
  }
`;

const BtnBox = styled.div`
  /* border: 1px solid #AFAFAF; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Btn = styled.button`
  background: transparent;
  outline: none;

  width: 52px;
  height: 44px;
  border: 1px solid #afafaf;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
  line-height: 24.2px;
  @media (max-width: 800px) {
    width: 32px;
    height: 24px;
  }
`;
const CartItemBox = styled.div`
  width: 100%;
border: 1.5px solid rgb(227, 227, 227);
  min-height: 127px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 0;
  border-radius: 8px;
`;
const ImageBox = styled.div`
  background: #efefef;
  width: 110px;
  height: 99px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    width: 101px;
    height: 90px;
  }
  /* width: 30%; */
`;

const ItemDetailsBox = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 800px) {
    padding: 10px;
  }
`;

const AttributeName = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  color: #3e4d50;
  margin: 10px 0;
`;
const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
`;
const AttriButeValueBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AttribteValue = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 5px;
`;
const ItemHeading = styled.div`
  font-family: "Inter";
  font-weight: 500;
  line-height: 21.78px;
  text-align: justify;
  font-size: 19px;
  @media (max-width: 800px) {
    margin-bottom: 7px;
    line-height: 15px;
    font-size: 14px;
    /* max-height: 500px;
    overflow-x: auto; */
  }
`;

const ProductvariantDetailsHeading = styled.div`
  font-family: "Inter";
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 5px;
  @media (max-width: 800px) {
    font-size: 11px;
  }
`;
const ProductvariantDetails = styled.div`
  font-family: "Inter";
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  @media (max-width: 800px) {
    font-size: 11px;
  }
`;
const SmallDetails = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    font-size: 11px;
  }
`;
const PriceAndCartBtnsBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
const PriceBox = styled.div`
  display: flex;
`;

const SellingPrice = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24.2px;
  color: #000000;
  @media (max-width: 800px) {
    font-size: 15px;
  }
`;
const MRP = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24.2px;
  text-decoration: line-through;
  color: #9a9a9a;
  margin-left: 5px;
  @media (max-width: 800px) {
    font-size: 15px;
  }
`;


const Color = styled.div`
  font-family: "Inter";
  font-size: 14;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;
  color:#838484;
    @media (max-width: 600px) {
     font-size: 10px;
  }

  
`;
function Cartitem({ item, getUserCartInfo, hide = false }) {
  const dispatch = useDispatch();
  const finalProduct = item?.product_variant
    ? { ...item?.product, ...item?.product_variant }
    : item?.product;
  const Icon =
    item?.shipment_type === "AIR"
      ? AirplanemodeActiveTwoToneIcon
      : DirectionsBoatFilledOutlinedIcon;

  function getAllAttributesString(items) {
    let str = "";
    items?.forEach((el, index) => {
      if (index + 1 === items.length) {
        str += `${el?.name}`;
      } else {
        str += `${el?.name}/`;
      }
    });
    return str;
  }
  function getAllAttributesValuesString(items) {
    let str = "";
    items?.attrbute_value?.forEach((el, count) => {
      if (count + 1 === items?.attrbute_value?.length) {
        str += `${el?.value}`;
      } else {
        str += `${el?.value}/`;
      }
    });

    return str;
  }
  return (
    <CartItemBox>
      <ImageBox>
        <img
          style={{
            width: "60%",
          }}
          src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${finalProduct?.image_urls[0]}`}
        />
      </ImageBox>

      <ItemDetailsBox>
        <ItemHeading>{finalProduct?.name}</ItemHeading>
        {finalProduct?.contain_variants ? (



          <>
            {/* <ProductvariantDetailsHeading>
              {getAllAttributesString(finalProduct?.attributes)}
            </ProductvariantDetailsHeading>
            <ProductvariantDetails>
              {getAllAttributesValuesString(finalProduct)}
            </ProductvariantDetails> */}

<Color>Color: {finalProduct?.color}</Color>
          </>
        ) : null}
        <PriceAndCartBtnsBox>
          {finalProduct?.discountedPrice ? (
            <PriceBox>
              <SellingPrice>
                {formatDollars(finalProduct?.discountedPrice)}
              </SellingPrice>
              <MRP>{formatDollars(finalProduct?.MRP)}</MRP>
            </PriceBox>
          ) : (
            <PriceBox>
              <SellingPrice>{formatDollars(finalProduct?.MRP)}</SellingPrice>
            </PriceBox>
          )}
          {!hide && (
            <CartitemBtn item={item} getUserCartInfo={getUserCartInfo} />
          )}
        </PriceAndCartBtnsBox>
      </ItemDetailsBox>
    </CartItemBox>
  );
}

function CartitemBtn({ item, getUserCartInfo }) {
  const [loading, setLoading] = useState(false);

  async function addToCart() {
    try {
      setLoading(true);
      await post("/api/cart/add-to-cart-or-increase-quantity", {
        productId: item?.product_id,
        shipment_type: item?.shipment_type,
        ...(item?.product_variant_id && {
          productVariantId: item?.product_variant_id,
        }),
      });

      setLoading(false);
      toast.success("Added to cart!");
      getUserCartInfo();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
      console.log(e);
    }
  }
  async function removeFromCart() {
    try {
      setLoading(true);
      await post("/api/cart/remove-from-cart-or-decrease-quantity", {
        cartItemId: item?.id,
      });

      setLoading(false);
      toast.success("Cart updated!");
      getUserCartInfo();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
      console.log(e);
    }
  }
  async function deleteItemFromCart() {
    try {
      setLoading(true);
      await post("/api/cart/delete-cart-item", {
        cartItemId: item?.id,
      });

      setLoading(false);
      toast.success("Removed from cart!");
      getUserCartInfo();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
      console.log(e);
    }
  }
  return (
    <BtnsBoxWrapper>
      <BtnBox>
        <Btn disabled={loading} onClick={removeFromCart}>
          <RemoveCircleOutlineOutlinedIcon style={{ color: "#999999" }} />
        </Btn>
        <Btn>{item?.quantity}</Btn>
        <Btn disabled={loading} onClick={addToCart}>
          <ControlPointOutlinedIcon style={{ color: "#999999" }} />
        </Btn>
      </BtnBox>
      <DeleteOutlinedIcon
        onClick={deleteItemFromCart}
        style={{
          marginLeft: "10px",
          color: "#999999",
          fontSize: "35px",
          cursor: "pointer",
        }}
      />
    </BtnsBoxWrapper>
  );
}

export default Cartitem;
