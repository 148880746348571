import React from "react";
import styled from "@emotion/styled";
import TermsItem from "../../components/TermsAndConditions/TermsItem";
import PolicyItem from "../../components/PrivacyPolicy/PolicyItem";
import Title from "../../components/PrivacyPolicy/Title";
import { setUserDetails } from "../../store/reducer/reducer";
import { useDispatch, useSelector } from "react-redux";
import { get, post } from "../../api_helper/api_helper";
import { useEffect } from "react";
const Heading = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 43.57px;
  margin: 30px auto;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 26px;
  }
`;

const Paratitle = styled.span`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
`;
const ParaDetail = styled.span`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
`;
const HeadingWithParaWrapper = styled.div`
  width: 80%;
  text-align: justify;
  margin: 15px auto;
`;

const ListWrapper = styled.div`
  width: 80%;
  margin: 15px auto;
  padding-left: 20px;
`;
const ListItem = styled.li`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #595959;
`;
const TextItem = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #595959;
  width: 80%;
  margin: 10px auto;
`;

function PrivacyPolicy() {

  const dispatch = useDispatch();

  useEffect(() => {
      const fetchUserDetails = async () => {
        try {
          const { userProfile } = await get("/api/auth/profile-info");
          dispatch(setUserDetails(userProfile || {}));
        } catch (e) {
          console.error("Error fetching user details:", e);
        }
      };
  
      fetchUserDetails();
    }, [dispatch]);	


  return (
    <>
      <Heading>Privacy Policy</Heading>
      <div style={{ marginBottom: "40px" }}>&nbsp;&nbsp;</div>

      <PolicyItem
        description={`At Shipora, we value your privacy and are committed to protecting your personal data. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use the Shipora app ("App"). By using the App, you agree to the terms of this Privacy Policy.`}
      />

      <Title heading={`1. Information We Collect`} />
      {/* <HeadingWithPara
        detail={` Name, address, email address, and phone number. Payment details for processing transactions.`}
        heading={`1.1. Personal Information:`}
      />
      <HeadingWithPara
        detail={` Details of the items you ship, including weight, dimensions, and destination addresses.`}
        heading={`1.2. Shipment Information:`}
      />
      <HeadingWithPara
        detail={` IP address, device type, operating system, and usage statistics.`}
        heading={`1.3. Device Information:`}
      />

      <HeadingWithPara
        detail={` Real-time location data for shipment tracking, if you grant permission.`}
        heading={`1.4. Location Data:`}
      />

      <HeadingWithPara
        detail={` Any information you voluntarily provide through support requests or feedback.`}
        heading={`1.5. Other Information:`}
      /> */}

<ListBox arr={List5} />
      <Title heading={`2. How We Use Your Information`} />
      <ListBox arr={List1} />
      <Title heading={`3. Information Sharing`} />
      <TextItem>
        3.1. We do not sell or rent your personal data. However, we may share your information with:
      </TextItem>

      
      <ListBox arr={List2} />

      <Title heading={`4. Cookies and Tracking Technologies`} />

      <TextItem>
      The App may use cookies or similar technologies to enhance your experience. You can manage or disable cookies through your device settings.
      </TextItem>
      <Title heading={`5. Data Security`} />
      <TextItem>
      We implement industry-standard security measures to protect your data. However, no method of electronic transmission or storage is completely secure, and we cannot guarantee absolute security.
      </TextItem>
      <Title heading={`6. Your Rights`} />
      <TextItem>
      Depending on your location, you may have the following rights:
      </TextItem>
      <ListBox arr={List3} />

      <Title heading={`7. Data Retention`} />
      <TextItem>
      We retain your data only as long as necessary for the purposes outlined in this policy or to comply with legal obligations.
      </TextItem>
      <Title heading={`8. Third-Party Links`} />

      <TextItem>
      The App may contain links to third-party websites. We are not responsible for the privacy practices of these external sites.
      </TextItem>
      <Title heading={`9. Children's Privacy`} />

      <TextItem>
      The App is not intended for individuals under 18 years of age. We do not knowingly collect personal information from children.
      </TextItem>
      {/* <ListBox arr={List3} /> */}
      <Title heading={`10. International Users`} />
      <TextItem>
      If you access the App from outside Canada, your data may be transferred to and processed in Canada, where privacy laws may differ from your jurisdiction.
      </TextItem>

      <Title heading={`11. Updates to This Policy`} />
      <TextItem>
      We may update this Privacy Policy periodically. The revised policy will be posted in the App, and continued use constitutes your acceptance of the changes.
      </TextItem>

      <Title heading={`12. Contact Us`} />
      <TextItem>
      If you have questions about this Privacy Policy or your data, please contact us:
      </TextItem>
      <ListBox arr={List4} />
      <div style={{ marginBottom: "40px" }}>&nbsp;&nbsp;</div>
    </>
  );
}

function HeadingWithPara({ heading, detail }) {
  return (
    <HeadingWithParaWrapper>
      {" "}
      <Paratitle>{heading}</Paratitle> <ParaDetail>{detail}</ParaDetail>
    </HeadingWithParaWrapper>
  );
}

function ListBox({ arr }) {
  return (
    <ListWrapper>
      {arr.map((el) => {
        return <ListItem>{el}</ListItem>;
      })}
    </ListWrapper>
  );
}
export default PrivacyPolicy;

const List1 = [
  "Process and fulfill shipments.",
  "Communicate with you about your orders, including updates and notifications.",
  "Improve the App’s functionality and user experience.",
  "Comply with legal obligations.",
  "Conduct fraud prevention and security checks."
];

const List2 = [
  <><strong>Service Providers:</strong> Third-party partners who assist in shipment processing, payments, and other operations.</>,
  <><strong>Law Enforcement: </strong> When required by law or to protect our legal rights.</>,
  <><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets.</>,

];

const List3 = [
  <><strong>Access and Correction:</strong> Request access to or correction of your personal data.</>,
  <><strong>Law Enforcement: </strong> Request deletion of your data, subject to legal and operational requirements.</>,
  <><strong>Opt-Out:</strong> Opt-out of receiving promotional communications.</>,
];

const List4 = [
  <><strong>Email:</strong>info@shipora.com</>,
  <><strong>Phone: </strong> +1 (647) 241-7111</>,
  // <><strong>Opt-Out:</strong> Opt-out of receiving promotional communications.</>,
];


const List5 = [
  <><strong>Personal Information:</strong>Name, address, email address, and phone number. Payment details for processing transactions.</>,
  <><strong>Shipment Information:</strong> Details of the items you ship, including weight, dimensions, and destination addresses.  </>,
  <><strong>Device Information:</strong> IP address, device type, operating system, and usage statistics..</>,
  <><strong>Location Data:</strong> Real-time location data for shipment tracking, if you grant permission.</>,
  <><strong>Other Information:</strong> Any information you voluntarily provide through support requests or feedback.</>
];
