import React, { useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserInfo,
  isRegistering,
  showOtpModal,
  tempRegistrationData,
} from "../../store/reducer/reducer";
import styled from "@emotion/styled";
import OtpImage from "../../assets/images/getOtpImage.svg";
import { toast } from "react-toastify";
import { Button } from "@mui/base";
import { post } from "../../api_helper/api_helper";
import { CircularProgress } from "@mui/material";
import CloseModal from "./CloseModal";
import { useEffect } from "react";
const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border-radius: 16px;
 width: clamp(393px, 30%, 670px);
  padding: 20px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    width: 90%;
  }
`;

const ResendOtp = styled.div`
  font-family: "Inter";
  font-size: 15px;
  font-weight: 400;
  line-height: 18.15px;
  text-align: center;
  margin-bottom: 10px;
`;

const ShowImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TexttoDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Heading = styled.div`
  font-weight: 400;
  font-size: 20px;
  font-family: "Inter";
  margin: 10px auto;
`;
const SubHeading = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-family: "Inter";
  margin: 10px auto;
`;

function ShowOtpModal() {
  const tempUserInfo = useSelector(
    (state) => state.general.tempRegistrationData
  );
  const isRegisteringStatus = useSelector(
    (state) => state.general.isRegistering
  );
  const displayModal = useSelector((state) => state.general.showOtpModal);
  const dispatch = useDispatch();

  useEffect(() => {
    if (displayModal) {
      // Disable scrolling by applying overflow hidden to the body
      document.documentElement.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling by resetting overflow to auto
      document.documentElement.style.overflow = 'auto';
    }

    // Cleanup function to ensure scrolling is restored when the component is unmounted
    return () => {
      document.documentElement.style.overflow = 'auto';
    };
  }, [displayModal]); // Trigger effect whenever the isOpen state chang
  function onComplete(text) {
    console.log(text);
  }
  const length = 4;

  const [OTP, setOTP] = useState(Array(length).fill(""));
  const inputRefs = useRef(Array(length).fill(null));
  const [loading, setLoading] = useState(false);
  const encryptPhoneNumber = (phoneNumber) =>
    phoneNumber?.replace(
      /(\d{5})(\d+)/,
      (_, visible, hidden) => `${visible}${"*".repeat(hidden.length)}`
    );

  async function resendOtp() {
    try {
      setLoading(true);
      const info = await post("/api/auth/resendOtp", {
        mobilenumber: tempUserInfo?.phone_number,
      });
      toast.success(info?.message);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
    }
  }

  const verifyOtp = async () => {
    try {
      setLoading(true);

      const userInfo = await post("/api/auth/verify", {
        mobilenumber: tempUserInfo?.phone_number,
        otp: OTP.join(""),
      });
      dispatch(addUserInfo(userInfo));
      localStorage.setItem("auth_token", userInfo.accessToken);
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      setLoading(false);
      dispatch(showOtpModal(false));
      if (isRegisteringStatus) {
        toast.success("Registration Successful!");
        dispatch(isRegistering(false));
        dispatch(tempRegistrationData(null));
      }
      setOTP(Array(length).fill(""));
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
    }
  };
  const handleTextChange = (input, index) => {
    const newPin = [...OTP];
    newPin[index] = input;
    setOTP(newPin);

    if (input.length === 1 && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (input.length === 0 && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }

    if (newPin.every((digit) => digit !== "")) {
      onComplete(newPin.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && OTP[index] === "") {
      inputRefs.current[index - 1]?.focus();
    }
  };
  return (
    <>
      <Modal
        open={displayModal}
        onClose={() => {
          dispatch(showOtpModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LoginFormWrapper>
          <CloseModal
            closeFn={() => {
              dispatch(showOtpModal(false));
            }}
          />
          <ShowImage>
            <img
              alt="otp"
              src={OtpImage}
              style={{ width: "171px", height: "140px", margin: "40px auto" }}
            />
          </ShowImage>

          <TexttoDisplay>
            <Heading>Please enter the OTP to verify your account</Heading>
            <SubHeading>
              A OTP has been sent to{" "}
              {`${tempUserInfo?.country_code}-${encryptPhoneNumber(
                tempUserInfo?.phone_number
              )}`}
            </SubHeading>
          </TexttoDisplay>
          <div
            style={{
              margin: "20px auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
           
            }}
          >
            {Array.from({ length }, (_, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={OTP[index]}
                onChange={(e) => handleTextChange(e.target.value, index)}
                onKeyDown={(e) => {
                  if (
                    !/[0-9]/.test(e.key) &&
                    !["Backspace", "ArrowLeft", "ArrowRight", "Tab"].includes(
                      e.key
                    )
                  ) {
                    e.preventDefault();
                  }

                  handleKeyDown(e, index);
                }}
                ref={(el) => (inputRefs.current[index] = el)}
                style={{
                  fontFamily: "Inter",
                  fontWeight: 400,
                  fontSize: "24px",
                  marginRight: index === length - 1 ? "0" : "10px",
                  width: "54px",
                  height: "54px",
                  border: "1px solid #B4B4B4",
                  borderRadius: "4px",
                  textAlign: "center",
                }}
              />
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={verifyOtp}
              disabled={loading}
              style={{
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: "20px",
                height: "54px",
                color: "white",
                background: "#00B4D8",
                borderRadius: "8px",
                border: "none",
                width: "224px",
                margin: "20px auto",
              }}
            >
              {loading ? <CircularProgress /> : `Verify`}
            </Button>
          </div>
          <ResendOtp onClick={() => resendOtp()}>Resend OTP</ResendOtp>
        </LoginFormWrapper>
      </Modal>
    </>
  );
}

export default ShowOtpModal;
