import React, { useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import CountryCodes from "../../assets/json/countrycode.json";

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import CloseModal from "../Auth/CloseModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { put } from "../../api_helper/api_helper";
import { Padding } from "@mui/icons-material";
const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border-radius: 16px;
  width: clamp(393px, 30%, 670px);
  padding: 20px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    width: 90%;
  }
`;
const Heading = styled.div`
  font-family: "Inter";
  font-size: 28px;
  line-height: 33.89px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const labelStyles = {
  display: "block",
  marginBottom: "5px",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  fontWeight: 500,
  color: "#5E6363",
};
function UpdateUserEmail({
  setShowEmailOtp,
  showUpdateUserMobileModal,
  setShowOtpModal,
  setShowEmailModal,
  setPhoneNumber,
  phoneNumber,
  userInfo,
  couuntryCode,
}) {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.general.user);
  // console.log("Mail",userInfo.email)
  async function updateUserMobileNumber() {
    if (!phoneNumber) {
      return toast.error("Email cannot be empty");
    }
    
    // Validate email format using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(phoneNumber)) {
      return toast.error("Please enter a valid email address");
    }

    if (userInfo?.email === phoneNumber) {
      return toast.error("The new email address cannot be the same as the current one");
    }
    
    try {
      setLoading(true);
      await put("/api/auth/profile-update-email", {
        email: user?.email,
      });
      setShowEmailOtp(true);
      setShowEmailModal(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "Something went wrong");
      console.log(e);
    }
  }

  // console.log("Check the email", phoneNumber)
  return (
    <>
      <Modal
        open={showUpdateUserMobileModal}
        onClose={() => {
          setShowEmailModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LoginFormWrapper>
          <CloseModal
            closeFn={() => {
              setShowEmailModal(false);
            }}
          />
          <Heading>Enter New Email</Heading>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography component="label" htmlFor="email" sx={labelStyles}>
                Email
              </Typography>
              <TextField
                fullWidth
                id="email"
                // label="Email"
                name="email"
                variant="outlined"
                placeholder="johndoe@gmail.com"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "rgba(227, 227, 227, 1)",
                  },
                }}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                margin: "20px auto",
                background: "#00B4D8",
                color: "white",
                borderRadius: "24px",
                width: "80%",
                height: "43px",
              }}
              variant="contained"
              type="submit"
              onClick={updateUserMobileNumber}
              disabled={loading}
            >
              {loading ? <CircularProgress /> : `Update`}
            </Button>
          </div>
        </LoginFormWrapper>
      </Modal>
    </>
  );
}

export default UpdateUserEmail;
