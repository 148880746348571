import React from "react";
import styled from "@emotion/styled";
import { formatDollars } from "../../helper/generalFunctions";
import NoImage from "../../assets/images/no-image.jpeg"
import './ShipmentTypeCard.css';

const CartItemBox = styled.div`
  width: 100%;
  min-height: 127px;
  display: flex;
  justify-content: space-between;
  margin-top:20px;
  align-items: center;
  /* margin: 20px auto;
  border-radius: 8px; */
`;
const CartItemBoxWrapper = styled.div`
  width: 90%;
border: 1.5px solid rgb(227, 227, 227);
  min-height: 127px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto;
  border-radius: 8px;
  padding: 15px;
  box-sizing: border-box;
`;
const ImageBox = styled.div`
width: 165px;
    height: 165px;
 border-radius: 6px;
box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);

  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
 width: 132px;
        height: 148px;
  }
`;

const Discount = styled.div`
  color: #da4848;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  margin-left: 10px;

     @media (max-width: 600px) {
     font-size: 14px;
  }

  
    @media (max-width: 919px) {
    font-size: 17px;

  }

    @media (max-width: 800px) {
    font-size: 15px;
  }

        @media (min-width: 800px) and (max-width: 1041px) {
  font-size: 17px;

  }

      @media (max-width: 1105px) {
margin-left:0;
  }
`;

const ItemDetailsBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 800px) {
    padding: 10px;
    width: 70%;
  }
`;

const ItemHeading = styled.div`
  font-family: "Inter";
  font-size: 24px;
  font-weight: 400;
  line-height: 29.05px;
  color: #323232;

  @media (max-width: 800px) {
    margin-bottom: 7px;
    line-height: 15px;
    font-size: 14px;
    /* max-height: 500px;
    overflow-x: auto; */
  }

        @media (min-width: 800px) and (max-width: 1041px) {
  font-size: 19px;

  }
`;

const ProductvariantDetails = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  color: #838484;

  @media (max-width: 800px) {
    font-size: 11px;
  }
`;
const PriceAndCartBtnsBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
const PriceBox = styled.div`
  display: flex;
  flex-direction:column;
    @media (max-width: 1105px) {
flex-direction:column;
  }
`;

const SellingPrice = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  color: #000000;
  @media (max-width: 800px) {
    font-size: 15px;
  }

      @media (min-width: 800px) and (max-width: 1041px) {
  font-size: 17px;

  }
`;
const MRP = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  text-decoration: line-through;
  color: #9a9a9a;
  margin-left: 5px;
  @media (max-width: 800px) {
    font-size: 15px;
  }
          @media (min-width: 800px) and (max-width: 1041px) {
  font-size: 17px;

  }

    @media (max-width: 400px) {
  margin-left: 0px;
  }
`;
const OrderDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30%;

    @media (max-width: 1621px) {
     width: 60%;
  }
`;

const OrderId = styled.span`
  color: #999999;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

const OrderStatus = styled.span`
  color: rgba(255, 166, 0, 1);
  background-color:rgba(255, 250, 210, 1);
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  border-radius:8px;
  line-height: 19.36px;
  padding: 7px 27px;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

const TrackBtn = styled.button`
  border: none;
  outline: none;
  background: #defaff;
  width: 285px;
  height: 40px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #19889f;
  cursor: pointer;
  border-radius:4px;

  @media (max-width: 800px) {
    width: 150px;
    font-size: 13px;
  }

    @media (min-width: 800px) and (max-width: 1041px) {
 width: 214px;

  }

    @media (max-width: 400px) {
    width: 127px;
        font-size:12px;

  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 77%;
  @media (max-width: 463px) {
    flex-direction: column;
  }

        @media (min-width: 800px) and (max-width: 1041px) {
margin-left: 5px;

  }
`;
const ViewOrderBtn = styled.button`
  border: none;
  outline: none;
  background: white;
  width: 285px;
  height: 40px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #7b7b7b;
  line-height: 20px;
  color: #454545;
  margin-top: 10px;
  cursor: pointer;
    border-radius:4px;
  @media (max-width: 800px) {
    width: 150px;
    font-size: 13px;
  }

      @media (min-width: 800px) and (max-width: 1041px) {
width: 214px;

  }

      @media (max-width: 400px) {
    width: 127px;
    font-size:12px;

  }
`;

const Parent = styled.div`
display:flex;

  @media (max-width: 800px) {
flex-direction:column;
  }



`

const ParentBelow = styled.div`
display:flex;

    @media (max-width: 1105px) {
flex-direction:column;
  }



`
const ParentAbove = styled.div`



  font-family: "Inter";
  font-size: 17px;
  font-weight: 400;
  line-height: 19.36px;
  display: flex;
  color:rgba(66, 69, 69, 1);

  align-items: center;
  @media (max-width: 800px) {
    font-size: 13px;
  }

        @media  (max-width: 450px) {
font-size:11px;
}

`

const SingleDetails = styled.div`
display:flex;
margin:11px 0;
gap:5px;

@media (min-width: 800px) and (max-width: 1150px) {
margin:10px 0;
}

@media  (max-width: 450px) {
margin:5px 0;
}
`;


const Color = styled.div`
  font-family: "Inter";
  font-size: 17px;
  font-weight: 400;
  line-height: 36px;
  text-align: justify;
  color:#838484;
    @media (max-width: 600px) {
     font-size: 14px;
  }

  
`;
function Cartitem({ setDisplayModalOrderInfo, item, setDisplayModal, setSelectedOrder }) {
  const finalProduct = item?.product_variant
    ? { ...item?.product, ...item?.product_variant }
    : item?.product;
  const calculateDiscountPercentage = () => {
    if (finalProduct?.discountedPrice && finalProduct?.MRP) {
      const discountedPrice = parseFloat(finalProduct.discountedPrice);
      const mrp = parseFloat(finalProduct.MRP);
      const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
      return Math.round(discountPercentage);
    }
    return 0;
  };
  function getVariantTypeString() {
    if (item?.product?.contain_variants) {
      let str = ``;
      item?.product_variant?.attrbute_value?.forEach((item) => {
        let attributeName = item?.attribute?.name;
        str += `${attributeName || ""}/${item.value || ""}   `;
      });
      return str;
    } else {
      return "";
    }
  }

  console.log(item?.order_status)


  return (
    <CartItemBoxWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          margin: "auto",
        }}
      >
        <OrderId>Order Id : #{item?.display_id}</OrderId>
        <OrderStatus>

          {/* {new Date(item?.createdAt).toLocaleDateString("en-GB", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })} */}
          {item?.order_status}
        </OrderStatus>
      </div>
      <CartItemBox>
        <ImageBox>
          <img
            style={{
              width: "125px",
            }}
            alt="cart-item"
            src={finalProduct?.image_urls?.length
              ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${finalProduct?.image_urls[0]}`
              : NoImage}
          />
        </ImageBox>

        <Wrap>
          <ItemDetailsBox>
            <ItemHeading>{finalProduct?.name}</ItemHeading>

            {finalProduct?.contain_variants ? (
            <Color>Color: {item?.product_variant?.color || "None"}</Color>) :""}
            {/* <ProductvariantDetails>
              {getVariantTypeString()}
            </ProductvariantDetails> */}
            <PriceAndCartBtnsBox>
              {finalProduct?.discountedPrice ? (
                <PriceBox>

                  {/* <SingleDetails>
                    <ParentAbove style={{ fontWeight: "600" }}>
                      Delivery Date:
                    </ParentAbove>
                    <ParentAbove>
                      11/9/24
                    </ParentAbove>

                  </SingleDetails> */}



                  <ParentBelow >


                    <Parent>
                      <SellingPrice>{formatDollars(finalProduct?.discountedPrice)}</SellingPrice>
                      <MRP>{formatDollars(finalProduct?.MRP)}</MRP>
                    </Parent>
                    <div>
                      <Discount>-{calculateDiscountPercentage()}% OFF</Discount>
                    </div>
                  </ParentBelow>


                </PriceBox>

              ) : (
                <PriceBox>
                  <SellingPrice>{formatDollars(finalProduct?.MRP)}</SellingPrice>
                </PriceBox>
              )}
            </PriceAndCartBtnsBox>
          </ItemDetailsBox>
          <OrderDetails>
            <TrackBtn
              onClick={() => {
                setDisplayModal(true);
                setSelectedOrder(item);
              }}
            >
              Track Order
            </TrackBtn>
            <ViewOrderBtn onClick={() => {
              setSelectedOrder(item);
              setDisplayModalOrderInfo(true)
            }}>View Order Details</ViewOrderBtn>
          </OrderDetails>
        </Wrap>
      </CartItemBox>
    </CartItemBoxWrapper>
  );
}

export default Cartitem;
