import React, { useState } from "react";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { post } from "../../api_helper/api_helper";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { isBuyNowItemSelected } from "../../store/reducer/reducer";
import { useNavigate } from "react-router-dom";

const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 55%;
  padding: 20px;
  @media (max-width: 800px) {
    width: 60%;
    margin: auto;
  }

    @media (max-width: 1100px) {
    width: 90%;
    margin: 10px auto;

  }
`;

const AddToCart = styled.div`
  border: 1px solid #19889f;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  color: #19889f;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
`;
const BuyNowBtn = styled.div`
  border: 1px solid transparent;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  background: #ffc727;
  color: #454545;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
`;

function AddToCartBuyNow({ selectedVariant, product ,typeOfShipment, qty}) {
  const [loading, setLoading] = useState(false);
  const user = useSelector(state=>state.general.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const matchingVariant = product?.product_variants?.find(variant => variant.id === selectedVariant);

  // const VarianceProduct = product?.contain_variants 
  // ? pro?.VarianceProduct[0] 
  // : null;
  // console.log(selectedVariant)

  // <AddToCartBtn
  //             onClick={() => {
  //               if (finalProduct?.contain_variants) {
  //                 toast.error("Please select variant");
  //                 dispatch(selectProductToFetchDetails(product));
  //                 navigate(`/product-details/${categoryName}/${subcategoryName}/${finalProduct.brand_name}/${finalProduct.name}`);
  //                 return;
  //               } else {
  //                 if (!user) {
  //                   // Handle guest cart for users not logged in
  //                   const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];

  //                   // Check if the product already exists in the guest cart
  //                   const existingItemIndex = guestCart.findIndex(
  //                     item => item.productId === finalProduct.id
  //                   );

  //                   if (existingItemIndex > -1) {
  //                     // Increase quantity if the item is already in the guest cart
  //                     guestCart[existingItemIndex].quantity += 1;
  //                   } else {
  //                     // Add new item to the guest cart
  //                     guestCart.push({
  //                       productId: finalProduct?.id,
  //                       productVariantId: finalProduct?.productVariantId,
  //                       image: finalProduct?.image_urls?.[0],
  //                       quantity: 1,
  //                       discountedPrice: finalProduct?.discountedPrice,
  //                       MRP: finalProduct?.MRP,
  //                       name:finalProduct?.name,
  //                       height:finalProduct?.height,
  //                       length:finalProduct?.length,
  //                       weight:finalProduct?.weight,
  //                       width:finalProduct?.width,
  //                       CartId:finalProduct?.id,
  //                       AirDuration:"70-80",
  //                       ShipDuration:"60-80",

                 
  //                     });
  //                   }
           
  //                   localStorage.setItem("guestCart", JSON.stringify(guestCart));
  //                   toast.success("Product has been added to your cart as a guest.");
  //                 } else {
  //                   // Handle logged-in users
  //                   dispatch(selectedProduct({ productId: finalProduct.id }));
  //                   dispatch(showSelectShipmentModal(true));
  //                 }
  //               }
  //             }}
  //           >
  //             <ShoppingCartOutlinedIcon />
  //             <span>Add to cart</span>
  //           </AddToCartBtn>


  


  async function addToCart() {
    try {
      // if(!user){
      //   toast.error("Please login")
      //   return
      // }


      // if (!user) {
      //   // Handle guest cart for users not logged in
      //   const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];

      //   // Check if the product already exists in the guest cart
      //   const existingItemIndex = guestCart.findIndex(
      //     item => item.productId === finalProduct.id
      //   );

      //   if (existingItemIndex > -1) {
      //     // Increase quantity if the item is already in the guest cart
      //     guestCart[existingItemIndex].quantity += 1;
      //   } else {
      //     // Add new item to the guest cart
      //     guestCart.push({
      //       productId: product.id,
      //       productVariantId: finalProduct?.productVariantId,
      //       image: finalProduct?.image_urls?.[0],
      //       quantity: 1,
      //       discountedPrice: finalProduct?.discountedPrice,
      //       MRP: finalProduct?.MRP,
      //       name:finalProduct?.name,
      //       height:finalProduct?.height,
      //       length:finalProduct?.length,
      //       weight:finalProduct?.weight,
      //       width:finalProduct?.width,
      //       CartId:finalProduct?.id,
      //       AirDuration:"70-80",
      //       ShipDuration:"60-80",

     
      //     });
      //   }

      //   localStorage.setItem("guestCart", JSON.stringify(guestCart));
      //   toast.success("Product has been added to your cart as a guest.");
      // }


      if (product?.contain_variants && !selectedVariant) {
        toast.error("Please select a variant to continue");
        return;
      }

      
      if(!product?.inStock){
        toast.error("Product is out of stock");
        return;
      }

      
      // if(!product?.inStock){
      //   toast.error("Product is out of stock");
      //   return;
      // }



      setLoading(true);
      // await post("/api/cart/add-to-cart-or-increase-quantity", {
      //   productId: product.id,
      //   shipment_type:typeOfShipment,
      //   quantity:qty,
      //   fromDetailsPage:true,
      //   ...(selectedVariant && { productVariantId: selectedVariant }),

      // });

      if (user) {
        // If user is logged in, add to cart via API
        await post(`/api/cart/add-to-cart-or-increase-quantity`, {
          productId: product.id,
          shipment_type:typeOfShipment,
          quantity:qty,
          fromDetailsPage:true,
          ...(selectedVariant && { productVariantId: selectedVariant }),
        });
        const successMessage = `Product has been added to your cart.`;
        toast.success(successMessage);
      } else {
        console.log("Clicked", matchingVariant)
        // If user is not logged in, add to cart in local storage
        const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];

        // Check if the product already exists in the guest cart
        const existingItemIndex = guestCart.findIndex(
          item => item.productId === product.id &&
            item.productVariantId === selectedVariant
        );

        if (existingItemIndex > -1) {
          // Increase quantity if the item is already in the guest cart
          guestCart[existingItemIndex].qty += 1;
        } else {
          // Add new item to the guest cart

          // console.log("Clicked", product)
          guestCart.push({
            productId: product.id,
            productVariantId: selectedVariant,
            shipment_type: typeOfShipment,
            image_urls: matchingVariant?.image_urls,
            image:product?.image_urls[0],
            quantity: qty,
            discountedPrice:product?.discountedPrice,
            MRP: product?.MRP,
            name: product?.name,
            width: product?.width,
            height: product?.height,
            length: product?.length,
            weight: product?.weight,
            VardiscountedPrice:matchingVariant?.discountedPrice,
            VarMRP: matchingVariant?.MRP,
            Varname: matchingVariant?.name,
            Varwidth: matchingVariant?.width,
            Varheight: matchingVariant?.height,
            Varlength: matchingVariant?.length,
            Varweight: matchingVariant?.weight,
       

            // ShippingPrice: priceValue * quantity,
            // AirDuration: shippingDetails?.durationAir,
            // ShipDuration: shippingDetails?.durationShip,
            // TotalPriceCart: WOLoginToPrice,
            CartId: product?.id,
            inStock: product?.inStock,
            VarianceProduct:matchingVariant,
            contain_variants:product?.contain_variants

          });
        }



        // Save updated guest cart to local storage
        localStorage.setItem("guestCart", JSON.stringify(guestCart));
        const successMessage = `Product has been added to your cart as a guest.`;
        toast.success(successMessage);
      }

      setLoading(false);
      // toast.success("Added to cart!")
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
      console.log(e);
    }
  }
  async function addToBuyNow() {
    try {
      if(!user){
        toast.error("Please login")
        return
      }
      if (product?.contain_variants && !selectedVariant) {
        toast.error("Please select a variant to continue");
        return;
      }

      if(!product?.inStock){
        toast.error("Product is out of stock");
        return;
      }
      setLoading(true);
      await post("/api/buy-now/add", {
        productId: product.id,
        shipment_type:typeOfShipment,
        user_id:user?.id,
        quantity:qty,
        ...(selectedVariant && { productVariantId: selectedVariant }),
      });

      setLoading(false);
      dispatch(isBuyNowItemSelected(true))
      navigate("/checkout")
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
      console.log(e);
    }
  }

  return (
    <BtnWrapper>
      <AddToCart onClick={addToCart}>
        {loading ? <CircularProgress /> : `Add to Cart`}
      </AddToCart>
      <BuyNowBtn onClick={addToBuyNow}>
      {loading ? <CircularProgress /> : `Buy Now`}
      </BuyNowBtn>
    </BtnWrapper>
  );
}

export default AddToCartBuyNow;
