import React, { useState } from "react";
import styled from "@emotion/styled";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AirplanemodeActiveTwoToneIcon from "@mui/icons-material/AirplanemodeActiveTwoTone";
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
import EditNoteTwoToneIcon from "@mui/icons-material/EditNoteTwoTone";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TypeOfShipment from "./TypeOfShipment";
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { formatDollars } from "../../helper/generalFunctions";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useNavigate } from "react-router-dom";
import AddToCartBuyNow from "./AddToCartBuyNow";

import { Box, Card, CardContent, Typography, Grid, Divider } from '@mui/material';
const ParentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

      @media (max-width: 800px) {
   width: 90%;
        margin: auto;
  }

      @media (max-width: 1100px) {
   width: 90%;
        margin: 10px auto;
  }
`;
const Heading = styled.div`
  font-family: "Inter";
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  text-align: justify;
    color:#3E4D50;
    @media (max-width: 600px) {
     font-size: 21px;
  }

  
`;

const Color = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  text-align: justify;
  color:#3E4D50;
    @media (max-width: 600px) {
     font-size: 17px;
  }

  
`;

const Seller = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  text-align: justify;
  color: #007185;
  margin-bottom: 7px;
  
  // Add underline on hover
  &:hover {
    text-decoration: underline;
  }
`;

const SellingPrice = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  color: #3e4d50;
  line-height: 38.73px;

     @media (max-width: 600px) {
     font-size: 20px;
  }
`;

const BtnsBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 800px) {
    margin-top: 10px;
  }
`;

const BtnBox = styled.div`
  /* border: 1px solid #AFAFAF; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Btn = styled.button`
  background: transparent;
  outline: none;

  width: 52px;
  height: 44px;
  border: 1px solid #afafaf;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
  line-height: 24.2px;
  @media (max-width: 800px) {
    width: 32px;
    height: 24px;
  }
`;

const BtnAb = styled.button`
  background: transparent;
  outline: none;
  display:flex;
  flex-direction:column;

  width: 52px;
  height: 44px;
  border: 1px solid #afafaf;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
  line-height: 24.2px;
  @media (max-width: 800px) {
    width: 32px;
    height: 24px;
  }
`;
const AttributeName = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  color: #3e4d50;
  margin: 10px 0;
`;
const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
`;
const AttriButeValueBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom:10px;
`;
const AttribteValue = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 5px;
`;
const MRP = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  margin-left: 10px;
  text-decoration: line-through;
  color: #999999;

     @media (max-width: 600px) {
     font-size: 17px;
  }
`;

const Discount = styled.div`
  color: #da4848;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  margin-left: 10px;

     @media (max-width: 600px) {
     font-size: 14px;
  }
`;

const InStock = styled.div`
    font-size: 15px;
    font-family: "Inter";
    font-weight: 400;
    padding: 8px 23px;
    border-radius: 6px;
    color: #4CBB6C;
    background: #E9F9EE;
    border: 1px solid #4CBB6C;
margin-bottom:7px;

`;



function ProductAddtoCartAndDetails({
  typeOfShipment,
  setTypeOfShipment,
  shippingDetails,
  product,
  selectedVariant,
  setSelectedVariant,
  qty,
  setQty,
  variantColor,
  setVariantColor,
  currentVariant,
  setCurrentVariant


}) {



  console.log("Variant Details",currentVariant)

  const handleVariantClick = (variant) => {
    setSelectedVariant(variant.id);  // Set the selected variant's ID
    console.log("Selected Variant:", variant);

    // Now you can handle updating any other relevant details like price, MRP, etc.
  };


  const selectedVariantDetails = product?.product_variants?.find(
    (variant) => variant.id === selectedVariant
  );

  console.log("Check Varientssss Cards Pagee", selectedVariantDetails)




  const finalProduct = product?.contain_variants
    ? { ...product, ...product?.product_variants[0] }
    : product;

  // const finalProduct = productSelected?.contain_variants && productSelected?.product_variants?.length > 0
  //   ? { ...productSelected, ...productSelected.product_variants[0] }
  //   : productSelected;

  // const Vendorproduct = product?.contain_variants && product?.product_variants?.length > 0
  // ? { ...product, ...product.product_variants[0] }
  // : product;

  // console.log("Selected Products", productSelected)



  // const measurements = [
  //   { label: "Width", value: finalProduct.width },
  //   { label: "Height", value: finalProduct.height },
  //   { label: "Length", value: finalProduct.length },
  //   { label: "Weight", value: finalProduct.weight },
  // ];
  const calculateDiscountPercentage = () => {
    const priceToUse = selectedVariantDetails
      ? selectedVariantDetails.discountedPrice
      : finalProduct.discountedPrice;
    const mrpToUse = selectedVariantDetails ? selectedVariantDetails.MRP : finalProduct.MRP;

    if (priceToUse && mrpToUse) {
      const discountedPrice = parseFloat(priceToUse);
      const mrp = parseFloat(mrpToUse);
      const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
      return Math.round(discountPercentage);
    }
    return 0;
  };




  const discountedPrice = finalProduct?.discountedPrice;
  // console.log("DP",discountedPrice)





  // console.log("Fp",finalProduct.brand_name)
  const sellerName = finalProduct?.brand_name;

  // function getAllAttributesString(items) {
  //   let str = "";
  //   items.forEach((el, index) => {
  //     if (index + 1 === items.length) {
  //       str += `${el?.name}`;
  //     } else {
  //       str += `${el?.name}/`;
  //     }
  //   });
  //   return str;
  // }

  function getAllAttributesString(items) {
    let str = "";
    if (Array.isArray(items) && items.length > 0) {
      items.forEach((el, index) => {
        if (index + 1 === items.length) {
          str += `${el?.name}`;
        } else {
          str += `${el?.name}/`;
        }
      });
    }
    return str;
  }

  // console.log("hello Product", finalProduct)

  return (
    <ParentWrapper>
      <Heading>{finalProduct?.name || ""}</Heading>
      <PriceContainer>
        {/* If a variant is selected, use its price, else use the base product's price */}
        {selectedVariantDetails ? (
          <>
            <SellingPrice>
              {formatDollars(selectedVariantDetails.discountedPrice)}
            </SellingPrice>
            <MRP>{formatDollars(selectedVariantDetails.MRP)}</MRP>
            <Discount>
              -{calculateDiscountPercentage(selectedVariantDetails.MRP, selectedVariantDetails.discountedPrice).toFixed(0)}% OFF
            </Discount>
          </>
        ) : (
          // Fallback to product's base price if no variant is selected
          <>
            {finalProduct?.discountedPrice ? (
              <>
                <SellingPrice>
                  {formatDollars(finalProduct?.discountedPrice)}
                </SellingPrice>
                <MRP>{formatDollars(finalProduct?.MRP)}</MRP>
                <Discount>
                  -{calculateDiscountPercentage(finalProduct?.MRP, finalProduct?.discountedPrice).toFixed(0)}% OFF
                </Discount>
              </>
            ) : (
              <SellingPrice>{formatDollars(finalProduct?.MRP)}</SellingPrice>
            )}
          </>
        )}
      </PriceContainer>


      { finalProduct?.inStock ?   <InStock>In Stock</InStock> : <InStock style={{color:"#DA4848", border:"1px solid #DA4848", background:"#ffdede"}}>Out Of Stock</InStock>}

  






      <div style={{ height: "0px" }}>

      </div>
      <CartitemBtn qty={qty} setQty={setQty} product={product} sellerName={sellerName} finalProduct={finalProduct} setSelectedVariant={setSelectedVariant} setVariantColor={setVariantColor} variantColor={variantColor} selectedVariant={selectedVariant} currentVariant={currentVariant} setCurrentVariant={setCurrentVariant}/>



      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
        <Box
          sx={{
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)', // Box shadow instead of border
            borderRadius: '8px',
            padding: '20px 16px',
            display: 'inline-block',
            width: 'fit-content',

            '@media (max-width: 430px)': {
              padding: '20px 0px', // Adjust padding when screen width is 430px or less
            },
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs style={{ padding: "0px 10px" }}>
              <Typography variant="body2" align="center" color="textSecondary" sx={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#424545',
                fontFamily:"Inter",
                '@media (max-width: 393px)': {
                  fontSize: '13px', // Apply font-size 11px on max-width 393px
                },
              }}>
                Width
              </Typography>
              <Typography variant="h6" align="center" sx={{
                fontSize: '15px',
                fontWeight: '500',
                color: '#838484',
                fontFamily:"Inter",
                '@media (max-width: 393px)': {
                  fontSize: '11px', // Apply font-size 11px on max-width 393px
                },
              }}>
                {finalProduct?.contain_variants ? currentVariant?.width : finalProduct?.width}(CM)
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

            <Grid item xs style={{ padding: "0px 10px" }}>
              <Typography variant="body2" align="center" color="textSecondary" sx={{
                fontSize: '16px',
                fontWeight: '500',
                fontFamily:"Inter",
                color: '#424545',
                '@media (max-width: 393px)': {
                  fontSize: '13px', // Apply font-size 11px on max-width 393px
                },
              }}>
                Height
              </Typography>
              <Typography variant="h6" align="center" sx={{
                fontSize: '15px',
                fontWeight: '500',
                fontFamily:"Inter",
                color: '#838484',
                '@media (max-width: 393px)': {
                  fontSize: '11px', // Apply font-size 11px on max-width 393px
                },
              }}>
                {finalProduct?.contain_variants ? currentVariant?.height : finalProduct?.height}(CM)
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

            <Grid item xs style={{ padding: "0px 10px" }}>
              <Typography variant="body2" align="center" color="textSecondary" sx={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#424545',
                fontFamily:"Inter",
                '@media (max-width: 393px)': {
                  fontSize: '13px', // Apply font-size 11px on max-width 393px
                },
              }}>
                Length
              </Typography>
              <Typography variant="h6" align="center" sx={{
                fontSize: '15px',
                fontWeight: '500',
                fontFamily:"Inter",
                color: '#838484',
                '@media (max-width: 393px)': {
                  fontSize: '11px', // Apply font-size 11px on max-width 393px
                },
              }}>
                {finalProduct?.contain_variants ? currentVariant?.length : finalProduct?.length}(CM)
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

            <Grid item xs style={{ padding: "0px 10px" }}>
              <Typography variant="body2" align="center" color="textSecondary" sx={{
                fontSize: '16px',
                fontWeight: '500',
                fontFamily:"Inter",
                color: '#424545',
                '@media (max-width: 393px)': {
                  fontSize: '13px', // Apply font-size 11px on max-width 393px
                },
              }}>
                Weight
              </Typography>
              <Typography variant="h6" align="center" sx={{
                fontSize: '15px',
                fontWeight: '500',
                fontFamily:"Inter",
                color: '#838484',
                '@media (max-width: 393px)': {
                  fontSize: '11px', // Apply font-size 11px on max-width 393px
                },
              }}>
                {finalProduct?.contain_variants ? currentVariant?.weight : finalProduct?.weight}(KG)
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div>

      <TypeOfShipment
        shippingDetails={shippingDetails}
        typeOfShipment={typeOfShipment}
        setTypeOfShipment={setTypeOfShipment}
        discountedPrice={discountedPrice}
        qty={qty}
        setQty={setQty}
        finalProduct={finalProduct}

        selectedVariant={selectedVariant}
      />

      {/* <AddToCartBuyNow qty={qty} typeOfShipment={typeOfShipment} product={product} selectedVariant={selectedVariant} /> */}


    </ParentWrapper>
  );
}
function CartitemBtn({ qty, setQty, product, sellerName, finalProduct, selectedVariant, setSelectedVariant , setVariantColor, variantColor, currentVariant, setCurrentVariant}) {
  const navigate = useNavigate();
  const VendorName = finalProduct?.vendor?.username;
  const handleVariantClick = (variant) => {
    setSelectedVariant(variant.id);  // Set the selected variant's ID
    console.log("Selected Variant:", variant);
    setVariantColor(variant.color)
    setCurrentVariant(variant)

    // Now you can handle updating any other relevant details like price, MRP, etc.
  };
  console.log("Inside CartItem", VendorName)

  const handleSellerClick = () => {
    navigate(`/store/${VendorName}`);
  };

  // const finalProduct = product?.contain_variants
  //   ? { ...product, ...product?.product_variants[0] }
  //   : product;



  return (

    <>


      {/* <BtnsBoxWrapper>
      <BtnBox>

        <Btn
          onClick={() => {
            if (qty > 1) {
              setQty((prev) => prev - 1);
            }
          }}
        >


          <RemoveCircleOutlineOutlinedIcon style={{ color: "#999999" }} />
        </Btn>
        <Btn>{qty}</Btn>


        <Btn
          onClick={() => {
            setQty((prev) => prev + 1);
          }}
        >
          <ControlPointOutlinedIcon style={{ color: "#999999" }} />
        </Btn>

      </BtnBox>

    </BtnsBoxWrapper> */}
      <Seller onClick={handleSellerClick} style={{ cursor: "pointer" }}>Visit the {sellerName} Store</Seller>
  



      {product?.contain_variants ? (
        <>
          {/* <AttributeName>
      {getAllAttributesString(finalProduct?.attributes)}
    </AttributeName> */}

    <Color>Color: {variantColor || "None" } </Color>
          <AttriButeValueBox>
            {product?.product_variants?.map((item, index) => {
              let str = "";
              item?.attrbute_value?.forEach((el, count) => {
                if (count + 1 === item?.attrbute_value?.length) {
                  str += `${el?.value}`;
                } else {
                  str += `${el?.value}/`;
                }
              });

              return (
                <AttribteValue
                  key={item.id}
                  style={{
                    color: selectedVariant === item?.id ? "#00B4D8" : "#3E4D50",
                    border: selectedVariant === item?.id ? "1px solid #00B4D8" : "1px solid #3E4D50",
                  }}
                  onClick={() => handleVariantClick(item)} // Handle variant click
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item?.image_urls && (
                      <img
                        src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item?.image_urls[0]}`}
                        alt={str}
                        style={{
                          width: "80px",
                          height: "80",

                          borderRadius: "4px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                    {str}
                  </div>
                </AttribteValue>
              );
            })}
          </AttriButeValueBox>
        </>
      ) : null}



      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0px' }}>
        <Box
          sx={{
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
            borderRadius: '4px',
            padding: '0px',
            display: 'inline-block',
            width: 'fit-content',
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs alignItems="center" justifyContent="center" style={{ padding: "8px 8px" }} onClick={() => {
              if (qty > 1) {
                setQty((prev) => prev - 1);
              }

            }}>
              <RemoveOutlinedIcon style={{ color: "black", cursor: "pointer", marginTop: "5px" }} />
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

            <Grid item xs style={{ color: "black", fontSize: "19px", fontWeight: "500", padding: "8px 8px" }}>
              {qty}
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

            <Grid item style={{ padding: "8px 8px" }} xs onClick={() => {
              setQty((prev) => prev + 1);
            }}>
              <AddOutlinedIcon style={{ color: "black", cursor: "pointer", marginTop: "5px" }} />
            </Grid>
            {/* <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} /> */}

            {/* <Grid item xs>
              <Typography variant="body2" align="center" color="textSecondary">
                Weight
              </Typography>
              <Typography variant="h6" align="center">
                {finalProduct.weight}(KG)
              </Typography>
            </Grid> */}
          </Grid>
        </Box>
      </div>




    </>

  );
}
export default ProductAddtoCartAndDetails;
