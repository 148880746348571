import LandingPage from "./pages/LandingPage";
import { Toaster, toast } from "react-hot-toast";
import { get } from "./api_helper/api_helper";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserDetails } from "./store/reducer/reducer";
function App() {
  // Apply custom styles for success and error globally
  const customToastOptions = {
    duration: 3000, // Default duration for all toasts
    icon: "🔔", // Default icon for all toasts
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      padding: "16px",
    },
  };

  // Overriding toast.success with custom success styles
  const customSuccessStyle = {
    border: "1px solid #4CAF50", // Green border
    backgroundColor: "#E8F5E9", // Light green background
    color: "#256029", // Green text
  };

  toast.success = (message) => {
    toast(message, {
      ...customToastOptions,
      style: {
        ...customToastOptions.style,
        ...customSuccessStyle, // Applying custom success styles
      },
    });
  };

  // Overriding toast.error with custom error styles
  const customErrorStyle = {
    border: "1px solid #FF0000", // Red border
    backgroundColor: "#FFEBEE", // Light red background
    color: "#D32F2F", // Dark red text
  };

  toast.error = (message) => {
    toast(message, {
      ...customToastOptions,
      style: {
        ...customToastOptions.style,
        ...customErrorStyle, // Applying custom error styles
      },
    });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { userProfile } = await get("/api/auth/profile-info");
        dispatch(setUserDetails(userProfile || {}));
      } catch (e) {
        console.error("Error fetching user details:", e);
      }
    };

    fetchUserDetails();
  }, [dispatch]);

  return (
    <>
      <Toaster
        position="bottom-center"
        toastOptions={{
          duration: 3000,
          icon: "🔔", // Default icon for all toasts
        }}
      />

      <LandingPage />

      {/* Now, you can use toast.success or toast.error directly in other components */}
    </>
  );
}

export default App;
