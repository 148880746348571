import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { get } from "./api_helper/api_helper";
import { setUserDetails } from "./store/reducer/reducer";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LayoutPage from "./pages/LayoutPage";
import LoginModal from "./components/Auth/LoginModal";
import { ToastContainer } from "react-toastify";
import SignUpModal from "./components/Auth/SignupModal";
import ShowOtpModal from "./components/Auth/ShowOtpModal";
import ForgotPasswordModal from "./components/Auth/ForgotPasswordModal";
import UpdatePasswordModal from "./components/Auth/UpdatePasswordModal";
import UpdatedPasswordSuccessfully from "./components/Auth/UpdatedPasswordSuccessfully";
import "react-toastify/dist/ReactToastify.css";
import ForgotPasswordOtp from "./components/Auth/ForgotPasswordOtp";
import Categories from "./pages/Categories";
import ProductListing from "./pages/Products";
import SelectTypeOfShipment from "./components/Products/SelectTypeOfShipment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import UserCart from "./pages/UserCart";
import OrderCheckout from "./pages/OrderCheckout/OrderCheckout";
import UserOrder from "./pages/UserOrders/UserOrder";
import Wishlist from "./pages/Wishlist/Wishlist";
import TermsAndCondition from "./pages/TermsAndCondition/TermsAndCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import AboutCompany from "./pages/AboutCompany/AboutCompany";
import FAQ from "./pages/FAQs/FAQ";
import UserAddress from "./pages/UserAddress/UserAddress";
import UserProfile from "./pages/UserProfile/UserProfile";
import DynamicPageContent from "./pages/DynamicPageContent";
import DisplaySubCategory from "./components/Category/SubCategory";

import ChildCategory from "./pages/ChildCategory";
import VendorStore from "./components/VendorStore/VendorStore";
import OrderPlaced from "./components/UserOrders/OrderPlaced";
import { Support } from "@mui/icons-material";
import UserSupport from "./pages/Support/Support";
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <StoreWrapper>
        <App />
      </StoreWrapper>
    ),
  },
  {
    path: "/categories",
    element: (
      <StoreWrapper>
        <Categories />
      </StoreWrapper>
    ),
  },

  {
    path: "/sub-categories",
    element: (
      <StoreWrapper>
        <DisplaySubCategory />
      </StoreWrapper>
    ),
  },

  {
    path: "/offers-section",
    element: (
      <StoreWrapper>
        <DynamicPageContent />
      </StoreWrapper>
    ),
  },
  {
    path: "/products",
    element: (
      <StoreWrapper>
        <ProductListing />
      </StoreWrapper>
    ),
  },
  // {
  //   path: "/product-details/:category/:subcategory/:brand/:productname",
  //   element: (
  //     <StoreWrapper>
  //       <ProductDetails />
  //     </StoreWrapper>
  //   ),
  // },

  {
    path: "/product-details/:category/:subcategory/:productname",
    element: (
      <StoreWrapper>
        <ProductDetails />
      </StoreWrapper>
    ),
  },
  {
    path: "/cart",
    element: (
      <StoreWrapper>
        <UserCart />
      </StoreWrapper>
    ),
  },
  {
    path: "/checkout",
    element: (
      <StoreWrapper>
        <OrderCheckout />
      </StoreWrapper>
    ),
  },
  {
    path: "/orders",
    element: (
      <StoreWrapper>
        <UserOrder />
      </StoreWrapper>
    ),
  },
  {
    path: "/wishlist",
    element: (
      <StoreWrapper>
        <Wishlist />
      </StoreWrapper>
    ),
  },
  {
    path: "/terms-and-condition",
    element: (
      <StoreWrapper>
        <TermsAndCondition />
      </StoreWrapper>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <StoreWrapper>
        <PrivacyPolicy />
      </StoreWrapper>
    ),
  },
  {
    path: "/user-address",
    element: (
      <StoreWrapper>
        <UserAddress />
      </StoreWrapper>
    ),
  },

  {
    path: "/user-support",
    element: (
      <StoreWrapper>
      <UserSupport></UserSupport>
      </StoreWrapper>
    ),
  },


  {
    path: "/user-profile",
    element: (
      <StoreWrapper>
        <UserProfile />
      </StoreWrapper>
    ),
  },
  {
    path: "/faq",
    element: (
      <StoreWrapper>
        <FAQ />
      </StoreWrapper>
    ),
  },
  {
    path: "/about",
    element: (
      <StoreWrapper>
        <AboutCompany />
      </StoreWrapper>
    ),
  },
  {
    path: "/child-category",
    element: (
      <StoreWrapper>
        <ChildCategory />
      </StoreWrapper>
    ),
  },

  {
    path: "/store/:vendorName",
    element: (
      <StoreWrapper>
        <VendorStore />
      </StoreWrapper>
    ),
  },

  {
    path: "/orderplaced",
    element: (
      <StoreWrapper>
<OrderPlaced/>
      </StoreWrapper>
    ),
  },

 
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

function StoreWrapper({ children }) {


  return (
    <Provider store={store}>
      <ToastContainer />
      <LayoutPage>
        <LoginModal />
        <SignUpModal />
        <ShowOtpModal />
        <UpdatePasswordModal />
        <ForgotPasswordModal />
        <ForgotPasswordOtp />
        <SelectTypeOfShipment />
        <UpdatedPasswordSuccessfully />
        {children}
      </LayoutPage>
    </Provider>
  );
}

reportWebVitals();
