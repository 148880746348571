import React from 'react'
import styled from '@emotion/styled'
import TermsItem from '../../components/TermsAndConditions/TermsItem';
import { setUserDetails } from "../../store/reducer/reducer";
import { useDispatch, useSelector } from "react-redux";

import { get, post } from "../../api_helper/api_helper";
import { useEffect } from 'react';
const Heading = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 43.57px;
  margin: 30px auto;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 26px;
  }
`;
function TermsAndCondition() {

  const dispatch = useDispatch();

  useEffect(() => {
      const fetchUserDetails = async () => {
        try {
          const { userProfile } = await get("/api/auth/profile-info");
          dispatch(setUserDetails(userProfile || {}));
        } catch (e) {
          console.error("Error fetching user details:", e);
        }
      };
  
      fetchUserDetails();
    }, [dispatch]);	
  return (
    <>
      <Heading>Term and Condition</Heading>
      {information.map((item, index) => {
        return (
          <TermsItem heading={item.title} description={item.detail} count={index + 1} />
        )
      })}
      <div style={{ marginBottom: "40px" }}>&nbsp;&nbsp;</div>
    </>
  )
}

export default TermsAndCondition


const information = [
  {
    "title": "Acceptance of Terms",
    "detail": "By using the App, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree, do not use the App."
  },
  {
    "title": "Services Offered",
    "detail": "Shipora provides shipment services including air and cargo shipping from India to Canada. The specific terms of service for each shipment will be detailed in individual contracts or agreements at the time of booking."
  },
  {
    "title": "User Eligibility",
    "detail": "You must be at least 18 years old to use the App. By using the App, you represent and warrant that you meet this eligibility requirement."
  },
  {
    title: "Account Registration",
    detail: (
      <ul>
        <li>You are required to create an account to use the App.</li>
        <li>You are responsible for maintaining the confidentiality of your login credentials.</li>
        <li>All information provided must be accurate and updated.</li>
      </ul>
    )
  },


  {
    title: "User Responsibilities",
    detail: (
      <ul>
        <li> Ensure all shipment details are correct and comply with applicable laws and regulations.</li>

        <li>
          Do not use the App for unlawful or prohibited activities.</li>
        <li>
          You are solely responsible for the contents of the shipment.</li>
      </ul>
    )
  },

  {
    title: "Prohibited Items",
    detail: (
      <>
        The following items are prohibited from shipment:


        <ul>

          <li>Hazardous materials, illegal substances, and restricted goods.</li>

          <li>
            Items violating international shipping regulations or the laws of the destination country.</li>

        </ul>
      </>

    )
  },

  {
    title: "Pricing and Payment",
    detail: (
      <ul>
        <li> All charges are displayed at the time of booking and are subject to applicable taxes and fees.</li>

        <li>
        Payments must be completed through the App's payment gateway.</li>
        <li>
        No refunds are issued unless specified under our Refund Policy.</li>
      </ul>
    )
  },

  {
    title: "Liability and Insurance",
    detail: (
      <ul>
        <li> Shipora is not liable for damage or loss of shipments unless you opt for insurance coverage.</li>

        <li>
        Insurance claims must be filed as per the terms of the insurance provider.</li>
 
      </ul>
    )
  },

  {
    title: "Cancellation and Refund Policy",
    detail: (
      <ul>
        <li> Cancellation policies are outlined at the time of booking.</li>

        <li>
        Refunds are subject to review and are not guaranteed for cancellations made after the shipment has been processed.</li>
 
      </ul>
    )
  },

  {
    "title": "Privacy Policy",
    "detail": "Your use of the App is subject to our Privacy Policy. Shipora collects and uses personal data in accordance with applicable privacy laws."
  },
  {
    "title": "Intellectual Property",
    "detail": "All content in the App, including but not limited to text, graphics, and logos, is the intellectual property of Shipora. You may not copy, modify, or distribute this content without prior written consent."
  },
  {
    "title": "Termination",
    "detail": "Shipora reserves the right to suspend or terminate your access to the App at any time for violations of these Terms."
  },
  {
    "title": "Limitation of Liability",
    "detail": "To the fullest extent permitted by law, Shipora will not be liable for any indirect, incidental, or consequential damages arising from the use of the App or services."
  },

  {
    title: "Governing Law and Dispute Resolution",
    detail: (
      <ul>
        <li>These Terms are governed by the laws of Canada.</li>

        <li>
        Any disputes shall be resolved through arbitration or in courts located in: 69 Argyle Crescent, Brampton, L6P1E4, Ontario, Canada.</li>
 
      </ul>
    )
  },

  {
    "title": "Amendments to Terms",
    "detail": "Shipora may update these Terms periodically. Continued use of the App after changes are made constitutes acceptance of the revised Terms."
  },
  {
    title: "Contact Us",
    detail: (

      <>
      For questions or concerns regarding these Terms, please contact us at:
      <ul>
        <li><strong>Email:</strong> info@shipora.io</li>

        <li>
        <strong>Phone: </strong>+1 (647) 241-7111</li>
 
      </ul>

      </>
    )
  },
]
