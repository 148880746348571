import React from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Grid,
    Card,
    CardContent,
    CardActions,
    Button,
    IconButton,
    TextField,
    Box,
    Pagination,
    useMediaQuery,
    useTheme,
    Snackbar,
} from '@mui/material';
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import vendor_slider from '../../assets/images/vendor_slider.png';
import styled from "@emotion/styled";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';
import { get, post } from "../../api_helper/api_helper";
import ProductSingleProduct from "../Products/SingleProduct";
import SingleProduct from "../ProductDetails/SingleProduct";
import VendorSingleProduct from "./VendorSingleProduct";
import Carousel from "react-material-ui-carousel";
import { clamp, debounce } from "lodash"; // Import debounce from lodash or write your own debounce function
import './Vendor.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';

import { setUserDetails } from "../../store/reducer/reducer";
import { useDispatch } from "react-redux";

const VendorStore = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [favorites, setFavorites] = useState([]);
    const [cart, setCart] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [products, setProducts] = useState([]);
    const [VendorSliderImage, setVendorSliderImage] = useState([]);
    const [VendorCategories, setVendorCategories] = useState([]);
    const { vendorName } = useParams();
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredProducts, setFilteredProducts] = useState(products);
    const [currentPage, setCurrentPage] = useState(1);
    const [wishlistItems, setWishlistItem] = useState([]);
    const user = useSelector((state) => state.general.user);
    
    const itemsPerPage = 5; // Number of products per page

    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(products.length / itemsPerPage);


    const dispatch = useDispatch();

    useEffect(() => {
        const fetchUserDetails = async () => {
          try {
            const { userProfile } = await get("/api/auth/profile-info");
            dispatch(setUserDetails(userProfile || {}));
          } catch (e) {
            console.error("Error fetching user details:", e);
          }
        };
    
        fetchUserDetails();
      }, [dispatch]);	

    const [firstImageLoaded, setFirstImageLoaded] = useState(false);

    const handleImageLoad = () => {
        if (!firstImageLoaded) {
            setFirstImageLoaded(true);
        }
    };

    async function getUserWishlistItems() {
        if (user) {
          // Fetch wishlist items from API for logged-in users
          try {
            const items = await post("/api/wishlist", {});
            setWishlistItem(items?.wishlist?.wishlist_items || []);
          } catch (error) {
            console.error("Error fetching wishlist items:", error);
          }
        } else {
          // Fetch wishlist items from local storage for guest users
          const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
          setWishlistItem(storedWishlist);
        }
      }
    
    
    
    
      // useEffect(() => {
      //   console.log("PopularProductSlider: wishlistItems updated:", wishlistItems);
      // }, [wishlistItems]);
    
    
      useEffect(() => {
        // Call this on initial render to load wishlist items
        getUserWishlistItems();
      }, []); // Run only once on mount



    const [categoryID, setCategoryID] = useState(null);
    console.log("Selected", categoryID)

    const [showShareOptions, setShowShareOptions] = useState(false);

    useEffect(() => {
        getProductsByCategory();
    }, [categoryID]);

    useEffect(() => {
        // Reset to page 1 when products change
        setCurrentPage(1);
    }, [products]);

    const handleShareClick = () => {
        setShowShareOptions((prev) => !prev);
    };
    // const totalPages = Math.ceil(products.length / itemsPerPage);


    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleShare = (platform) => {
        let url = `https://marketplace.shipora.io/store/${vendorName}`; // Replace with the URL you want to share
        let text = "Check this out!";

        switch (platform) {
            case "whatsapp":
                window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(text + ' ' + url)}`);
                break;
            case "facebook":
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`);
                break;
            // case "instagram":
            //     alert("Instagram sharing not supported via direct URL. Please use the Instagram app.");
            //     break;
            // Add more cases for other platforms if needed
            default:
                break;
        }
    };


    // console.log("SLUG", vendorName)

    const [VendorID, setVendorID] = useState();


    async function getVendorDetails() {
        try {
            const data = await post(`/api/vendor-details`, { vendor_name: vendorName });
            const vendordt = data || [];
            

            if (vendordt.id) {
                setVendorID(vendordt.id);
            } else {
                console.error('Vendor ID is not available in the response.');
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function getProductsByCategory() {
        try {
            if (!VendorID) return; // Ensure VendorID is set

            if (categoryID === null) {
                const allProducts = await post(`/api/vendor-store-all-products`, { vendor_id: VendorID });
                setProducts(allProducts || []);
            } else {
                const data = await post(`/api/vendor-store-categories-products`, { category_id: categoryID, vendor_id: VendorID });
                setProducts(data || []);
            }
        } catch (e) {
            console.error("Error fetching products for category:", e);
        }
    }

    useEffect(() => {
        getProductsByCategory();
    }, [categoryID]);




    useEffect(() => {
        getVendorDetails();
        getVendorStoreCategories();


    }, []);

    useEffect(() => {
        if (VendorID) {
            getVendorStoreBannerImages();
            getVendorStoreAllProducts();

        }
    }, [VendorID]);

    // let TestId = "fbff2438-65fc-4c23-a436-a4fea0e8b6ea";

    useEffect(() => {
        console.log("Products length:", products.length);
    }, [products]);








    async function getVendorStoreBannerImages() {
        if (VendorID) {
            console.log("VendorID before API call:", VendorID); // Confirm VendorID
            try {
                const data = await post(`/api/vendor-store-banner-images`, { vendor_id: VendorID });
                console.log("API response for banner images:", data);

                // Check if 'allBannerImages' exists and is an array, then set it to state
                if (data?.status && Array.isArray(data.allBannerImages)) {
                    setVendorSliderImage(data.allBannerImages);
                } else {
                    console.error("Expected allBannerImages array but received:", data);
                    setVendorSliderImage([]);
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            console.error('VendorID is undefined or invalid');
        }
    }

    // To verify VendorSliderImage updates as expected:
    useEffect(() => {
        console.log("Updated VendorSliderImage:", VendorSliderImage);
    }, [VendorSliderImage]);



    async function getVendorStoreCategories() {
        try {
            console.log("Sending vendor_name:", vendorName); // Verify vendorName value
            const data = await post(`/api/vendor-store-categories`, { vendor_name: vendorName });
            console.error("API Error:", data?.message || "No error message available");

            if (data) {
                console.log("VendorCategories response:", data);
                const vendorCategories = data || [];
                console.log("VendorCategories", vendorCategories);
                setVendorCategories(vendorCategories)
            } else {
                console.error("No data received from getVendorStoreCategories API call.");
            }
        } catch (e) {
            if (e.response) {
                console.error("Error Response Data:", e.response.data); // Shows server response details
                console.error("Error Status:", e.response.status); // Shows the status code
                console.error("Error Headers:", e.response.headers); // Shows the headers
            } else {
                console.error("Error in getVendorStoreCategories:", e);
            }
        }

    }




    async function getVendorStoreAllProducts() {
        try {
            const data = await post(`/api/vendor-store-all-products`, { vendor_id: VendorID }); // Your API call
            const vendorAllProducts = data || []; // Assuming data is an array of sections
            console.log("VendorAllProducts", vendorAllProducts)
            setProducts(vendorAllProducts)






        } catch (e) {
            console.log(e);
        }
    }

    // async function getVendorStoreProductsOnCategory() {
    //     try {
    //         const data = await post(`/api/vendor-store-categories-products`, { vendor_id: VendorID , category_id:categoryID}); // Your API call
    //         const CategoryProducts = data || []; // Assuming data is an array of sections
    //         console.log("Category", CategoryProducts)
    //         setProducts(CategoryProducts)






    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    useEffect(() => {
        const delayedSearch = debounce(() => {
            if (!searchTerm) {
                getProductsByCategory(); // Show all products if searchTerm is empty
                return;
            }

            const filteredProducts = products.filter((product) =>
                product.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setProducts(filteredProducts);
        }, 300); // Adjust delay as needed (300ms is common)

        delayedSearch(); // Call the debounced function

        return () => delayedSearch.cancel(); // Cleanup debounce on unmount or when searchTerm changes
    }, [searchTerm]);

    // Handle input change to set the search term
    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };



    const WidthAdjuster = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;
  max-width:1525px;
  @media (max-width: 600px) {
    width: 97%;
  }
`;


    const Slider = styled.div`

    background-color:grey;
    height:500px;
    margin-bottom:20px;



    `;

    const Brder = styled.div`
     width:100%;
     border:1px solid grey;
     margin-bottom: 10px;
     `;

    //     const BottomShare = styled.div`
    //   display:flex;
    //   flex-direction:column;
    //   justify-content:center;
    //   align-items:center;
    //   margin:20px;
    //      `;

    //     const Sharebtn = styled.div`


    //     padding: 10px 96px;

    //    box-shadow:0px 0px 2px rgba(0, 0, 0, 0.25);

    //         border-radius: 8px;
    //         cursor:pointer;
    //     margin: 13px;

    //     font-size:18px;

    //      `;

    //     const SharePara = styled.div`
    //      margin-top:10px;

    //     font-size:18px;

    //         `;

    const BottomShare = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

    const ShareBtn = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

    const SharePara = styled.p`
  margin: 0;
  font-size: 14px;
  color: #555;
`;

    const ShareOptions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

    const ShareOption = styled.a`
  margin: 5px 0;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

    const ProductContainer = styled.div`
  margin: 20px auto;
`;

    const FlexWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin: 50px 0;

    @media (max-width: 1434px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const PaginationUI = styled.div`
    margin: 74px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    padding: 10px 14px;
    border-top: 1.4px solid rgba(0, 0, 0, 0.2);

         @media (max-width: 600px) {
    width:  80% ;

  }

`;

    const ParentProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  cursor: pointer;
  height: auto;
  margin: 10px;
  max-width: 100%;
  position:relative;

  @media (min-width: 600px) {
    max-width: 500px;
    margin: 20px 18px;
  }
`;

    const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 100%; // Creates a 1:1 aspect ratio
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

const CustomPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    color: #00B4D8;
    border-color: #00B4D8;
  }
  .MuiPaginationItem-root.Mui-selected {
    background-color: #00B4D8;
    color: white;
    width:36px;
    height:36px;
    border-radius:19px;
    font-size:16px;
  }
  .MuiPaginationItem-ellipsis {
    color: #00B4D8;
  }
  .MuiPaginationItem-icon {
    color: #00B4D8;
  }
`;

    const ProductName = styled.div`
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  margin: 17px 10px 0;
  text-align: left;
  height: 38px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

    const PriceBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px;

  @media (max-width: 922px) {
    font-size: 0.88rem;
  }
`;

    const SellingPrice = styled.span`
  font-weight: bold;
  color: black;
`;

    const MRP = styled.span`
  text-decoration: line-through;
  color: #999;
  margin-left: 5px;
`;

    const Discount = styled.span`
  color: #FF6D6D;
  margin-left: 10px;
  font-weight: bold;
  margin-bottom:19px;
`;

    const AddToCartBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #00B4D8;
  border: 1px solid #00B4D8;
  border-radius: 5px;
  padding: 10px;
      margin: 0px auto;
    margin-bottom: 19px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: calc(100% - 20px);
  font-size: 16px;

  &:hover {
    background-color: #008CBA;
    color:white;
  }
`;

    const PaginationInfo = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
`;




    const PositionAbsolute = styled.div`
position: absolute;
right: 1px;
top: 10px;
z-index: 1000;
margin-right:5px;
color:black;
`;




const handlePageChange = (event, value) => {
    setCurrentPage(value);

    window.scrollTo(0, 0); // Scroll to the top when the component is mounted

  };


    return (

        <Box sx={{ flexGrow: 1 }}>



            {VendorSliderImage?.length > 0 ? (

                <Carousel
                    autoPlay={true}
                    indicators={true}
                    navButtonsAlwaysVisible={true} // Keep it true if you want to manage visibility manually
                    NavButton={({ style, onClick }) => null} // Override navigation buttons to render nothing
                >
                    {VendorSliderImage.map((item, i) => (
                        <div
                            key={item.id}
                            style={{
                                width: isMobile ? "97%" : "80%",
                                display: "flex",
                                justifyContent: "center",
                                margin: "0px auto",
                                paddingTop: "50px",
                                maxWidth: "1525px",
                                maxHeight:"500px",
                              




                            }}
                        >
                            <img
                                src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item?.image_url}`}
                                style={{
                                    cursor: "pointer",
                                    width: "100%",
                                    maxHeight: "500px",
                                    height: "clamp(50px, 70vw, 500px)",  // Apply clamp for height
                             
                  
                                    borderRadius: "0px",
                                }}
                                alt={`Slide ${i + 1}`}
                          

                            />
                        </div>
                    ))}
                </Carousel>
            ) : null}







            <WidthAdjuster>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', width: "67%", justifyContent: "space-between" }}>
                        <Button
                            color="inherit"
                            sx={{
                                mb: 1,
                                color: categoryID === null ? 'black' : 'grey',
                            }}
                            onClick={() => setCategoryID(null)}
                        >
                            All
                        </Button>

                        {VendorCategories?.map((category) => (
                            <Button
                                key={category.id}
                                color="inherit"
                                sx={{
                                    mb: 1,
                                    color: categoryID === category.id ? 'black' : 'grey',
                                }}
                                onClick={() => setCategoryID(category.id)}
                            >
                                {category.name}
                            </Button>
                        ))}
                    </Box>


                    <TextField
                        variant="outlined"
                        placeholder="Search Products..."
                        value={searchTerm}
                        onChange={handleInputChange} // Updates searchTerm continuously
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => setSearchTerm("")}> {/* Clear search on click */}
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                        sx={{ mb: 1, minWidth: '300px' }}
                    />


                </Box>
            </WidthAdjuster>



            <WidthAdjuster>


                <ProductContainer>
                    <FlexWrapper>
                        {currentProducts.map((item) => (
                            <VendorSingleProduct
                                key={item.id}
                                product={item}
                                displayAddToCartBtn={true}
                                getUserWishlistItems={getUserWishlistItems}
                                wishlistItems={wishlistItems}


                            />
                        ))}
                    </FlexWrapper>
                </ProductContainer>
            </WidthAdjuster>

            {products?.length > itemsPerPage     && (
        <PaginationUI>
          <PaginationInfo>
            {`Page ${currentPage} of ${totalPages}`}
          </PaginationInfo>
          <Stack spacing={2}>
            <CustomPagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                "& .MuiPaginationItem-root.Mui-selected": {
                  borderRadius: "6px !important",
                },


              }}
            />
          </Stack>
        </PaginationUI>
      )}

            <WidthAdjuster>

                {/* <BottomShare>
                    <Sharebtn>Share</Sharebtn>
                    <SharePara>Share this page with your friends</SharePara>
                </BottomShare> */}
                <div className="bottom-share">
                    <div className={`share-options ${showShareOptions ? 'show fade-in' : ''}`}>
                        <span className="share-option" onClick={() => handleShare("whatsapp")}>
                            <WhatsAppIcon style={{ color: "#25D366", fontSize: "36px" }} />
                        </span>
                        <span className="share-option" onClick={() => handleShare("facebook")}>
                            <FacebookIcon style={{ color: "#1877F2", fontSize: "36px" }} />
                        </span>
                        {/* <span className="share-option" onClick={() => handleShare("instagram")}>
                    <InstagramIcon style={{ color: "#C13584", fontSize: "36px" }}/>
                </span> */}
                        {/* Add more sharing options as needed */}
                    </div>
                    <button className="share-btn" onClick={handleShareClick}>Share</button>
                    <p className="share-para">Share this page with your friends</p>
                </div>


            </WidthAdjuster>













        </Box>
    )
}

export default VendorStore;