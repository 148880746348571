import React, { useState } from "react";
import { useEffect } from "react";
import styled from "@emotion/styled";
import { Button, Menu, MenuItem } from "@mui/material";
import PersonIcon from "../../assets/images/person.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { addUserInfo, showLoginModal } from "../../store/reducer/reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import Person2Icon from "@mui/icons-material/Person2";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { toast } from "react-toastify";
import { setUserDetails } from "../../store/reducer/reducer";

import { get, put } from "../../api_helper/api_helper";
const ToolIcon = styled.img`
  width: 16px;
  height: 18px;
  margin-right: 8px;
  @media (max-width: 600px) {
    width: 12px;
    height: 14px;
  }
`;

const AddressItem = styled.span`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  color: #19889f;
`;

const MenuItemDiv = styled.div`
  width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 20px;
  box-sizing: border-box;
  margin: 20px 0;
  cursor: pointer;
`;
function UserProfile() {
  const [profileFetched, setProfileFetched] = useState(false);
  const matches = useMediaQuery("(max-width:600px)");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useSelector((state) => state.general.user);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ClickInfo = useSelector((state) => state.general.ClickInfo);
  const userDetails = useSelector((state) => state.general.userDetails);

  const infouser = JSON.parse(localStorage.getItem("userInfo")) || null;
  useEffect(() => {
    console.log("Checking the information Live:", userDetails);
  }, [userDetails]);
  console.log("check", ClickInfo);
  // console.log("sadjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjvh")

  const [loading, setLoading] = useState(false);

  const [userInfo, setUserInfo] = useState({});



  useEffect(() => {
    const handleScroll = () => {
      if (open) {
        setOpen(false); // Close the menu on scroll
      }
    };

    // Add event listener on mount
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [open]);
  // useEffect(() => {
  //   if (ClickInfo) {
  //     // Call the getUserProfile function when ClickInfo is true
  //     getUserProfile();
  //   }
  // }, [ClickInfo]); // Trigger the effect whenever ClickInfo changes

  // useEffect(() => {

  //     getUserProfile();

  // }, [userInfo]); // Only trigger when `user` changes
  async function getUserProfile() {
    try {
      setLoading(true);
      const { userProfile } = await get("/api/auth/profile-info");
      setUserInfo(userProfile || {});
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
    }
  }

  // console.log("Hello User Info", userInfo)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const CommonStyles = {
    color: "white",
    marginRight: matches ? "12px" : "16px",
    fontFamily: "Inter",
    fontWeight: 500,
    textTransform: "capitalize",
    fontSize: matches ? "12px" : "16px",
    padding: 0,
  };

  const ItemStyle = { color: "#19889F", marginRight: "20px", fontSize: "20px" };
  const MenuItems = [
    {
      name: "Personal Details",
      icon: Person2Icon,
      func: () => {
        navigate("/user-profile");
      },
    },
    {
      name: "Address",
      icon: AddLocationIcon,
      func: () => {
        navigate("/user-address");
      },
    },
    // {
    //   name: "Become a Supplier/Vendor",
    //   icon: AccountBoxIcon,
    //   func: () => {},
    // },
    {
      name: "About",
      icon: MapsUgcIcon,
      func: () => {
        navigate("/about");
      },
    },
    {
      name: "Support",
      icon: PsychologyAltIcon,
      func: () => {
        navigate("/user-support");
      },
    },
    {
      name: "FAQ",
      icon: HelpRoundedIcon,
      func: () => {
        navigate("/faq");
      },
    },
    {
      name: "Logout",
      icon: LogoutIcon,
      func: () => {
        setOpen((prev) => !prev);
        dispatch(addUserInfo(null));
        handleClose();
        localStorage.clear();
        navigate("/");
      },
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <Button
        style={CommonStyles}
        onClick={(e) => {
          if (user) {
            setOpen((prev) => !prev);
            handleClick(e);
            return;
          }
          dispatch(showLoginModal(true));
        }}
      >
        <ToolIcon src={PersonIcon} />
        {user
          ? `${userDetails?.first_name || ""} ${userDetails?.last_name || ""} `
          : `Log in/Sign up`}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          handleClose();
          setOpen((prev) => !prev);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {MenuItems.map((item) => {
          return (
            <MenuItemDiv
              key={item?.name}
              onClick={() => {
                item.func();
                setOpen(false);
              }}
            >
              <item.icon style={{ color: "#19889F", marginRight: "20px" }} />
              <AddressItem>{item?.name}</AddressItem>
            </MenuItemDiv>
          );
        })}
      </Menu>
    </div>
  );
}

export default UserProfile;
