import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from "@emotion/styled"

const StyledAccordion = styled(Accordion)`
  width: 100%;
  box-shadow: none;
  border-bottom: 1px solid #e0e0e0;
  margin: 0;

  &:before {
    display: none;
  }

  &.Mui-expanded {
    margin-top:0px ;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  &.Mui-expanded {
    min-height: unset; /* Ensure no extra height on expand */
  }

  & .MuiAccordionSummary-content {
    margin: 0;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0 0 16px 0;

  &.MuiAccordionDetails-root {
    color: #7B7B7B !important; /* Change content color to #7B7B7B */
    font-family: "Inter" !important;
    margin-top: 0 !important; /* Remove extra margin at the top */
    
    h1 {
      margin-top: 0 !important; /* Set margin-top to 0 */
    }

    ul {
      list-style-type: disc; /* Example: bullet points */
      margin-top: 0 !important; /* Set margin-top to 0 */
      
      li {
        color: #7B7B7B !important; /* Change content color */
        font-family: "Inter" !important;

        span {
          color: #7B7B7B !important; /* Change content color */
          font-family: "Inter" !important;
          font-size: 16px !important;
        }
      }
    }
  }
`;


const Heading = styled.span`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
`;

export default function DetailsAccordion({ name, info }) {
  return (
    <StyledAccordion>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${name}-content`}
        id={`${name}-header`}
      >
        <Heading>{name}</Heading>
      </StyledAccordionSummary>
      <StyledAccordionDetails dangerouslySetInnerHTML={{ __html: info }} />
    </StyledAccordion>
  );
}
